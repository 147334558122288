// import React, { useState, useEffect } from 'react';

// const Slideshow = ({ slides }) => {
//     const [slideIndex, setSlideIndex] = useState(0);
//     const [fadeClass, setFadeClass] = useState(''); // State to handle fade effect

//     const plusSlides = (n) => {
//         setFadeClass(''); // Reset fade class before updating the slide
//         setTimeout(() => {
//             showSlides(slideIndex + n);
//         }, 10); // Short delay to allow fade out effect
//     };

//     const currentSlide = (n) => {
//         setFadeClass(''); // Reset fade class before updating the slide
//         setTimeout(() => {
//             showSlides(n - 1);
//         }, 10); // Short delay to allow fade out effect
//     };

//     const showSlides = (n) => {
//         let newIndex = n;
//         if (n >= slides.length) {
//             newIndex = 0;
//         } else if (n < 0) {
//             newIndex = slides.length - 1;
//         }
//         setSlideIndex(newIndex);
//         setFadeClass('fade'); // Add fade class after updating the slide
//     };

//     useEffect(() => {
//         const interval = setInterval(() => {
//             plusSlides(1);
//         }, 10000); // Adjust interval as needed

//         return () => clearInterval(interval);
//     }, [slideIndex]); // Restart interval when slideIndex changes

//     return (
//         <div className="slideshow-container">
//             {slides.map((slide, index) => (
//                 <div
//                     key={index}
//                     className={`mySlides ${index === slideIndex ? fadeClass : ''}`} // Apply fade only to active slide
//                     style={{ display: index === slideIndex ? 'block' : 'none' }} // Show only the active slide
//                 >
//                     <img src={slide.image} style={{ width: '100%' }} alt={`Slide ${index + 1}`} />
//                     <div className="text">{slide.caption}</div>
//                 </div>
//             ))}
//             <a className="prev" onClick={() => plusSlides(-1)}>&#10094;</a>
//             <a className="next" onClick={() => plusSlides(1)}>&#10095;</a>
//             <br />
//         </div>
//     );
// };

// export default Slideshow;










import React, { useState } from 'react';

const Slideshow = ({ slides }) => {
    const [slideIndex, setSlideIndex] = useState(0);

    const plusSlides = (n) => {
        showSlides(slideIndex + n);
    };

    const currentSlide = (n) => {
        showSlides(n - 1);
    };

    const showSlides = (n) => {
        let newIndex = n;
        if (n >= slides.length) {
            newIndex = 0;
        } else if (n < 0) {
            newIndex = slides.length - 1;
        }
        setSlideIndex(newIndex);
    };

    return (
        <div className="slideshow-container">
            {slides.map((slide, index) => (
                <div
                    key={index}
                    className={`mySlides ${index === slideIndex ? 'active' : ''}`} // Apply 'active' class to the current slide
                    style={{ display: index === slideIndex ? 'block' : 'none' }} // Show only the active slide
                >
                    <img src={slide.image} style={{ width: '100%' }} alt={`Slide ${index + 1}`} />
                    <div className="text">{slide.caption}</div>
                </div>
            ))}
            <a className="prev" onClick={() => plusSlides(-1)}>&#10094;</a>
            <a className="next" onClick={() => plusSlides(1)}>&#10095;</a>
            <br />
        </div>
    );
};

export default Slideshow;