import React, {useEffect } from 'react';


import { Link } from 'react-router-dom';

import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
import {motion} from 'framer-motion';
import CursorFollower from '../components/cursorFollower';

import { CDN } from '../config';


function Project1() {
    

    gsap.registerPlugin(ScrollTrigger);
    

    useEffect( () => {
        
        document.getElementById("GNCTD").addEventListener('contextmenu', (e)=>{
            e.preventDefault();
        })
        
        gsap.from( ".cover-bg img", {duration:1, y:-50,scale:0.9} )
        gsap.from(".main-header span", {duration:0.8, delay:1,opacity:0, stagger:0.2, y:"80%",ease: "back.out(1.7)"})
        gsap.from( ".project-container p",{ 
            scrollTrigger:{
                trigger:".project-container p"
        },duration:0.5, opacity:0.5,x:"20px" })


        
        gsap.to(".us-b-a-left",{
            scrollTrigger:{
                trigger:".us-b-a-left",
                start:"top 70%",
                scrub:true,
                end:"+=120"
        }, transform:"translate(0px, 0px)"})
        gsap.to(".us-b-a-right",{
            scrollTrigger:{
                trigger:".us-b-a-left",
                start:"top 70%",
                scrub:true,
                end:"+=120"
        }, transform:"translate(0px, 0px)"})
        gsap.to(".us-b-a-left p",{
            scrollTrigger:{
                trigger:".us-b-a-left p",
                start:"top 70%",
                scrub:true,
                end:"+=120"
        }, transform:"translate(0px, 0px)"})

        gsap.to(".us-b-a-left-2",{
            scrollTrigger:{
                trigger:".us-b-a-left-2",
                start:"top 70%",
                scrub:true,
                end:"+=120"
        }, transform:"translate(0px, 0px)"})
        gsap.to(".us-b-a-right-2",{
            scrollTrigger:{
                trigger:".us-b-a-left-2",
                start:"top 70%",
                scrub:true,
                end:"+=120"
        }, transform:"translate(0px, 0px)"})
        gsap.to(".us-b-a-left-2 p",{
            scrollTrigger:{
                trigger:".us-b-a-left-2 p",
                start:"top 70%",
                scrub:true,
                end:"+=120"
        }, transform:"translate(0px, 0px)"})
        
        

    }, [])

    
    function refreshST(){
        ScrollTrigger.refresh(true);
    }; 
    setTimeout(()=>{
        refreshST()
    },2000) 

    
     
    return (
        <div><CursorFollower/>

        <motion.div 
        initial={{opacity:0}}
        animate={{opacity:1}}
        transition={{ duration: 1.5 }}
        exit={{opacity:0}}
         className="homer">
            
            <div id='GNCTD' className="project-1"> 
            
            <div className='gnctd_figma_frame'>
                {/* <iframe className='gnctd_figma' src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FztWtrsMWKu1gxf0xepVvgq%2FPortfolio%3Fpage-id%3D54%253A1219%26type%3Ddesign%26node-id%3D56-1309%26viewport%3D336%252C414%252C0.13%26t%3DtuhmJ0usA5vI42w6-1%26scaling%3Dscale-down-width%26mode%3Ddesign" allowfullscreen></iframe> */}
                {/* <iframe className='gnctd_figma' src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FztWtrsMWKu1gxf0xepVvgq%2FPortfolio%3Fpage-id%3D54%253A1219%26type%3Ddesign%26node-id%3D812-3811%26viewport%3D629%252C357%252C0.24%26t%3DBsOzWf0SpNX7s2Xj-1%26scaling%3Dmin-zoom%26mode%3Ddesign" allowfullscreen></iframe> */}
                <iframe className='gnctd_figma' src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FztWtrsMWKu1gxf0xepVvgq%2FPortfolio%3Fkind%3Dproto%26node-id%3D812-3811%26page-id%3D54%253A1219%26scaling%3Dscale-down-width%26t%3DaZVjRjUoVelEpJrQ-1%26type%3Ddesign%26viewport%3D629%252C357%252C0.24%26mode%3Ddesign" allowfullscreen></iframe>
            </div>
            
            
            <div className="other-projects-container">
                <h2 style={{textAlign:"center"}} >Other Projects</h2>
                <div className="other-projects">
                <div className="project op">
                    <Link style={{cursor:"none"}} to="/rogalik-bakery">
                        <img className="project-image second-p" src={`${CDN}/images/images/rogalik-thumbnail.png`} alt="" />
                    </Link>
                </div>

                <div className="project op">
                    <Link style={{cursor:"none"}} to="/mia-restaurant">
                        <img className="project-image third-p" src={`${CDN}/images/images/mia-thumbnail.png`} alt="" />
                    </Link>
                </div>
                </div>
            </div>


            </div>

        </motion.div>
        </div>
        
        );
    
}
 
export default Project1;