import React,{useEffect} from "react";
import CursorFollower from '../components/cursorFollower';
import {motion} from 'framer-motion';
import VanillaTilt from "vanilla-tilt";
import { HashLink } from 'react-router-hash-link';
import ReactGA from 'react-ga';

import Modal from "../components/projectModal";

import { NavLink, Link } from "react-router-dom";






import { CDN } from '../config';
   

function CourseProjects() {

    useEffect(()=>{ 
        const element = document.querySelector(".my-image");
        VanillaTilt.init(element);
        // element.addEventListener("tiltChange", callback);

        function openOverlay(title, description, link) {
            document.getElementById('overlay-title').textContent = title;
            document.getElementById('overlay-description').textContent = description;
            document.getElementById('overlay-link').href = link;
            document.getElementById('overlay').style.display = 'flex';
        }
    
        function closeOverlay() {
        document.getElementById('overlay').style.display = 'none';
        }

    })

    return (
        <div> <CursorFollower/>

        <motion.div
        animate={{opacity:1,  translateY:"0px"}}
        initial={{opacity:0, translateY:"50px"}}
        transition={{ duration: 1.5 }}
        exit={{opacity:0, translateY:"50px"}}
        className="course-projects-container">

            {/* <h1>Course Projects</h1> */}

            {/* <Modal /> */}

            <div className="course-project-tiles">

                <div className="course-project-tile">
                <div className="course-project-img"> <img loading="lazy" src={`${CDN}/projects/ArtVerse_web.png`} alt="" /> </div>
                    

                    <h2>ArtVerse – Bridging Gaps in Art Education</h2>
                    <ul>
                        <li>
                        ArtVerse is a VR museum that revolutionizes art education by making it accessible and interactive, breaking down barriers like cost and location to enhance global cultural appreciation.
                        </li>
                    </ul>
                        
                            <div className="project-ctas">
                                <div className="contactButton">
                                    {/* <a target="_blank" rel="noreferrer"  href="https://www.figma.com/proto/yeib8RBe40pOeMSK1J0ya2/IXDS-724---Rainbow-%F0%9F%8C%88?page-id=74%3A2&node-id=828-3121&viewport=-1225%2C960%2C0.09&t=iKLQqLiPmITZmzf6-9&scaling=min-zoom&starting-point-node-id=828%3A3121&show-proto-sidebar=1">
                                        Learn More
                                    </a> */}

                                    <Link to="/artverse">
                                        Learn More
                                    </Link>


                                </div>
                                

                                <a target="_blank" rel="noreferrer" className="primaryButton"  href="https://www.figma.com/proto/yeib8RBe40pOeMSK1J0ya2/IXDS-724---Rainbow-%F0%9F%8C%88?page-id=179%3A4&node-id=347-30&viewport=-240%2C679%2C0.15&t=1PMqugUNFEVAkog5-9&scaling=contain&starting-point-node-id=347%3A30&hotspot-hints=0&show-proto-sidebar=1">
                                    View Design
                                </a>

                            </div>
                </div>

                <div className="course-project-tile">
                <div className="course-project-img"> <img loading="lazy" src={`${CDN}/projects/GardenTag_web.png`} alt="" /> </div>
                    

                    <h2>GardenTag – Every Plant's Personal Caretaker</h2>
                    <ul>
                        <li>
                        GardenTag is a personalized plant care assistant that simplifies gardening by providing tailored health insights for each plant, ensuring optimal growth no matter your green thumb level.                        </li>

                    </ul>

                    <div className="project-ctas">
                        <div className="contactButton">
                            {/* <a target="_blank" rel="noreferrer"  href={`${CDN}/projects/IXDS734_FinalProject.pdf`}>
                                Learn More
                            </a> */}

                            <Link to="/gardentag">
                                Learn More
                            </Link>

                        </div>
                        

                        <a target="_blank" rel="noreferrer" className="primaryButton"  href="https://www.figma.com/proto/0tQGP8UAe7LdpJGK61rbRt/IXDS-734?page-id=405%3A37&node-id=620-1527&viewport=70%2C-21%2C0.07&t=vIrPvNjKFlSPrS2g-9&scaling=scale-down&starting-point-node-id=620%3A1527&show-proto-sidebar=1">
                            View Design
                        </a>
                    </div>

                </div>

                <div className="course-project-tile">
                <div className="course-project-img"> <img loading="lazy" src={`${CDN}/projects/AccuFit_web.png`} alt="" /> </div>
                    

                    <h2>Accufit – Tailored Tracking for Your Body</h2>
                    <ul>
                        <li>
                        AccuFit is an Apple Watch app that boosts fitness tracking accuracy by incorporating a body fat calculator, tailored to individual physiological profiles.                        
                        </li>
                        
                    </ul>

                    <div className="project-ctas">
                        <div className="contactButton">
                            <a target="_blank" rel="noreferrer"  href="https://www.figma.com/proto/2OAXZFvODlrl5INi4vVhTn/IXDS724?page-id=417%3A8246&node-id=608-999&viewport=5947%2C317%2C0.24&t=pRFR56hRparAFcm9-9&scaling=contain&starting-point-node-id=608%3A999&show-proto-sidebar=1">
                                Learn More
                            </a>
                        </div>
                        

                        <a target="_blank" rel="noreferrer" className="primaryButton"  href="https://www.figma.com/proto/2OAXZFvODlrl5INi4vVhTn/IXDS724?page-id=310%3A7584&node-id=622-4364&viewport=-11%2C556%2C0.52&t=QOJZcqkiYQ4o6asO-9&scaling=contain&starting-point-node-id=622%3A4364&show-proto-sidebar=1&hotspot-hints=0">
                            View Design
                        </a>
                    </div>

                </div>


                <div className="course-project-tile">
                <div className="course-project-img"> <img loading="lazy" src={`${CDN}/projects/Beemove.png`} alt="" /> </div>
                    

                    <h2>Human-Centered Interactive Design</h2>
                    <ul>
                        <li>
                            In our project, we utilized Maslow's Hierarchy of Needs within a human-centered design framework to address transportation problems facing by international graduate students at SCAD.
                        </li>
                        
                        <li>
                            Case Study : 
                            <a className="nocursor" href="https://www.canva.com/design/DAF-4wItIAo/nkg29KCWJmN4ZoB9LdPlNQ/view" target="_blank" rel="noreferrer"> Link </a>
                        </li>
                    </ul>


                </div>

                <div className="course-project-tile">
                <div className="course-project-img"> <img loading="lazy" src={`${CDN}/projects/Zillow.png`} alt="" /> </div>
                    

                    <h2>Human Experience Prototyping</h2>
                    <ul>
                        <li>
                            We created an interactive system using phenomenology as a theoretical framework and we acquire techniques for solving design challenges that impact the human experience.
                        </li>
                        
                        <li>
                            Case Study : 
                            <a className="nocursor" href="https://www.figma.com/proto/QDscdisSd8AgxvEnmsVVSR/Zillow-Virtual-Staging?page-id=2953%3A7502&type=design&node-id=2953-7503&viewport=11326%2C10031%2C0.65&t=j6r0U0KZDJTNUDUK-1&scaling=contain&mode=design" target="_blank" rel="noreferrer"> Link </a>
                        </li>
                    </ul>


                </div>
                

                <div className="course-project-tile">
                    <div className="course-project-img"> <img loading="lazy" src={`${CDN}/images/images/Web_Coding_html.jpeg`} alt="" /> </div>
                    

                    <h2>Web Development Methods</h2>
                    <ul>
                        <li>
                            5 Chinese Elements :
                            <a className="nocursor" href={`${CDN}/scad/sarathN_projectApart2-final-DD.pdf`} target="_blank" rel="noreferrer"> Design Document </a>
                        </li>
                        <li>
                            National Park Redesign :
                            <a className="nocursor" href={`${CDN}/scad/nekuriS_projectBpart1.pdf`} target="_blank" rel="noreferrer"> Design Document </a>
                            |
                            <a className="nocursor" href="https://studentpages.scad.edu/~snekur20/index.html" target="_blank" rel="noreferrer"> Website Link </a>

                        </li>
                        <li>
                            Single Page : 
                            <a className="nocursor" href="https://studentpages.scad.edu/~snekur20/Zombie_World/" target="_blank" rel="noreferrer"> Website Link </a>
                        </li>
                    </ul>


                </div>

                <div className="course-project-tile">
                    
                    <div className="course-project-img"> <img loading="lazy" src={`${CDN}/images/images/Coding.jpeg`} alt="" /> </div>
                    
                    
                    <h2>Programming for Designers</h2>
                    <ul>
                        <li>
                            Illustration pattern : 
                            <a className="nocursor" href={`${CDN}/scad/SCADNow_202320_ITGM522_ELearn_SarathNekuri_ComplexPattern.pdf`} target="_blank" rel="noreferrer"> Design Document </a>
                        </li>
                        <li>
                            Interactive Animation :  
                            <a className="nocursor" href={`${CDN}/scad/SCADNow_202320_ITGM522_ELearn_2023_SarathNekuri_Animation.pdf`} target="_blank" rel="noreferrer"> Design Document </a>
                            |
                            <a className="nocursor" href={`${CDN}/scad/Interactive_animation.mp4`} target="_blank" rel="noreferrer"> Output Video </a>
                            
                           

                        </li>
                        <li>
                            Course Project : 
                            <a className="nocursor" href={`${CDN}/scad/SCADNow_202320_ITGM522_Sarath_Nekuri_FinalProject.pdf`} target="_blank" rel="noreferrer"> Design Document </a>
                            |
                            <a className="nocursor" href={`${CDN}/scad/Course_Project.mp4`} target="_blank" rel="noreferrer"> Output Video </a>
                        </li>
                    </ul>

                </div>


            </div>

            

            
        </motion.div>

        </div> 
       
     );
}

export default CourseProjects
