import React, {useEffect, useState } from 'react';


import { Link } from 'react-router-dom';

import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
import MotionPathPlugin from "gsap/MotionPathPlugin";
import {motion} from 'framer-motion';
import CursorFollower from '../components/cursorFollower';
import ReactGA from 'react-ga';

import { CDN } from '../config';


import Slideshow from "../components/slideshow";


function GardenTag() {

    function refreshPage() {
        ScrollTrigger.refresh(true)
    }



    const [images, setImages] = useState([]);
    const totalFrames = 77; // Number of frames
    const triggerPoint = 6650; // Adjust this value to where you want the animation to start
    
    useEffect(() => {
        const imgArray = [];
        for (let i = 0; i < totalFrames; i++) {
            const img = new Image();
            const frameNumber = String(i).padStart(3, '0'); // Ensure the number is zero-padded
            img.src = `${CDN}/projects/gardentag/gardentag_sequence_u/${frameNumber}.png`;
            imgArray.push(img);
        }
        setImages(imgArray);


        // gsap.from(".gardentag_header span", {duration:0.8, opacity:0, stagger:0.2, y:"80%",ease: "back.out(1.7)"})

        gsap.registerPlugin(ScrollTrigger);
        gsap.registerPlugin(MotionPathPlugin);

        gsap.to( ".gardentag_header",{ 
            scrollTrigger:{
                trigger:".gardentag_img_sequence",
                start:"top 40%",
                stagger:"0.2",
                toggleActions:"play none none reset"
        },duration:0.2, opacity:1,y:0,scale:1 })

        gsap.to( ".gardentag_tagline",{ 
            scrollTrigger:{
                trigger:".gardentag_img_sequence",
                start:"top 30%",
                stagger:"0.2",
                toggleActions:"play none none reset"
        },duration:0.2, opacity:1,y:0,scale:1 })


        gsap.to( ".gardentag_sensors",{ 
            scrollTrigger:{
                trigger:".gardentag_img_sequence",
                start:"top 20%",
                stagger:"0.2",
                toggleActions:"play none none reset"
        },duration:0.2, opacity:1,y:0,scale:1 })


        



    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = document.documentElement.scrollTop;
            const viewportHeight = window.innerHeight;

            // Start animation after triggerPoint
            if (scrollTop >= triggerPoint && scrollTop <= triggerPoint + viewportHeight) {
                const scrollPercent = (scrollTop - triggerPoint) / viewportHeight;
                const currentFrame = Math.min(Math.floor(scrollPercent * totalFrames), totalFrames - 1);
                const canvas = document.getElementById('gardentag_img_sequence');
                if (canvas && images.length > 0) {
                    const ctx = canvas.getContext('2d');
                    const image = images[currentFrame];
    
                    const dpr = window.devicePixelRatio || 1;
                    canvas.width = 1656 * dpr; // Set canvas width to image width times device pixel ratio
                    canvas.height = 1198 * dpr; // Set canvas height to image height times device pixel ratio
                    ctx.scale(dpr, dpr); // Scale context to match device pixel ratio
    
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
    
                    // Draw image at native resolution
                    ctx.drawImage(image, 0, 0, 1656, 1198);
                }
            }
        };
        
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [images]);



    const slides_midfi = [
        { image: `${CDN}/projects/artverse/homepage_midfi.png`, caption: 'ArtVerse Homepage' },
        { image: `${CDN}/projects/artverse/topartists_midfi.png`, caption: 'Top Artists' },
        { image: `${CDN}/projects/artverse/360_artview_midfi.png`, caption: '360 Degree view page of Artpiece' },
        { image: `${CDN}/projects/artverse/artpiece_midfi.png`, caption: 'Art work description page' },
        { image: `${CDN}/projects/artverse/gallery_midfi.png`, caption: 'My Gallery page' }
    ];

    const slides_hifi = [
        { image: `${CDN}/projects/artverse/homepage_hifi.png`, caption: 'ArtVerse Homepage' },
        { image: `${CDN}/projects/artverse/topartists_hifi.png`, caption: 'Top Artists' },
        { image: `${CDN}/projects/artverse/360_artview_hifi.png`, caption: '360 Degree view of Artpiece' },
        { image: `${CDN}/projects/artverse/artpiece_hifi.png`, caption: 'Art work description page with Artist Introduction' },
        { image: `${CDN}/projects/artverse/gallery_hifi.png`, caption: 'My Gallery Page' }
    ];

    const slides_VR = [
        // { image: `${CDN}/projects/artverse/milly_vr.jpg`, caption: 'Milly Wen Prototyping on ShapesXR' },
        { image: `${CDN}/projects/artverse/MyGallery.gif`, caption: 'My Gallery' },
        { image: `${CDN}/projects/artverse/TopArtists.gif`, caption: 'Top Art pieces with description' }
    ];
     
    return (
        <div>
            {/* <CursorFollower/> */}

        <motion.div 
        animate={{opacity:1,  translateY:"0px"}}
        initial={{opacity:0, translateY:"50px"}}
        transition={{ duration: 1.5 }}
        exit={{opacity:0, translateY:"50px"}}
        className="homer artverse gardentag">

            <section style={{marginTop:'0rem'}} className='top'>
            
                <img style={{width:"80px"}} loading="lazy" src={`${CDN}/projects/gardentag/Logo.png`} alt=""/>
                            
                <h1>GardenTag – Every Plant's Personal Caretaker</h1>
                <p>
                GardenTag is a personalized plant care assistant that simplifies gardening by providing tailored health insights for each plant, ensuring optimal growth no matter your green thumb level. 
                </p>

                <ul>
                    <li className='list-block'>
                        <p>TIMELINE</p>
                        <p>Mar - May 2024</p>
                    </li>
                    <li className='list-block'>
                        <p>Team</p>
                        <p>Monan Qian & Sarath Chand Nekuri</p>
                    </li>
                    <li className='list-block'>
                        <p>Tools</p>
                        <p>Figma, Spline, Notion</p>
                    </li>
                </ul>

                <ul>
                    <li style={{width: '100%'}} className='list-block'>
                        <p>My Role</p>
                        <p>Research, User Interviews, Brand Style Guides, UI, UX, 3d Modeling & Prototyping</p>
                    </li>
                </ul>
                
                
            </section>

            {/* <iframe class='video' src="https://www.youtube.com/embed/_pwGqIBOuO4?si=OHHvQQLO20giGL-P" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}

            <section>
                <div className='projectHeader'>
                    <div className='number'>01</div>
                    <h2 className='header'>The Background</h2>
                    <p style={{fontSize: '48px'}}>&#x1F4DA;</p>
                </div>
                <p>
                Urban gardening has seen a resurgence as people seek to connect with nature, enjoy homegrown produce, and improve their living environments. However, gardeners face significant challenges such as:
                </p>

            </section>

            <div style={{width:'90%', maxWidth:"1024px"}} className='artverse_bg fdrow'>

            <div className='artverse_block_column'>
                <img className='card-img' loading="lazy" src={`${CDN}/projects/gardentag/gt_b1.jpg`} alt=""/>
                <div className='artverse_block_items'>
                    <p style={{fontSize:'24px', fontWeight:'600'}}>Inconsistent care guidelines</p>
                    <p>
                    Urban gardeners often face frustration with conflicting and generic plant care advice that doesn’t cater to their specific needs.        </p>
                </div>
            </div>

            <div className='artverse_block_column'>
            <img className='card-img' loading="lazy" src={`${CDN}/projects/gardentag/gt_b2.jpg`} alt=""/>
                <div className='artverse_block_items'>
                    <p style={{fontSize:'24px', fontWeight:'600'}}>Unpredictable weather conditions</p>
                    <p>
                    Changing weather patterns make it difficult for gardeners to maintain optimal growing conditions for their plants.
                    </p>
                </div>
            </div>

            <div className='artverse_block_column'>
            <img className='card-img' loading="lazy" src={`${CDN}/projects/gardentag/gt_b3.jpg`} alt=""/>
                <div className='artverse_block_items'>
                    <p style={{fontSize:'24px', fontWeight:'600'}}>Difficulty in monitoring</p>
                    <p>
                    Monitoring and maintaining plant health is a challenge due to the lack of accessible tools that provide real-time insights and personalized guidance.        </p>
                </div>
            </div>

            </div>
            
            <section>
                <div className='projectHeader'>
                    <div className='number'>02</div>
                    <h2 className='header'>Empathize</h2>
                    <p style={{fontSize: '48px'}}>&#x1F50D;</p>
                </div>
                <p>
                To understand the needs and challenges of gardeners, we conducted extensive research focusing on their general gardening experiences and the latest technologies in mobile applications.
                </p>

                <div style={{maxWidth:'800px'}} className='artverse_block_column'>
                        <div className='artverse_block_items'>
                            <img style={{width:"64px"}} loading="lazy" src={`${CDN}/projects/gardentag/gt_interview.svg`} alt=""/>
                            <p style={{fontSize:'24px', fontWeight:'600'}}>User Interviews</p>
                            <p >
                            We interviewed 5 gardening enthusiasts, ranging from beginners to experienced gardeners, to gain insights into their gardening practices, challenges, and motivations.
                            </p>
                        </div>
                    </div>

            </section>

            <div className='artverse_bg fdcolumn'>


            <div style={{marginBottom:'2rem'}} className='fdcolumn'>
                    

                    <div className='fdcolumn'>
                        <p style={{fontSize:'24px', fontWeight:'600'}}>Participant Quotations</p>
                        
                    <p> <b>- Gardening for Well-being:</b>  Many users grow plants and flowers to enhance their living spaces and for their mental health benefits.
                    </p>
                    <div className='fdrow'>

                        <div className='artverse_block_column'>

                        <img className='gt_quote_r' loading="lazy" src={`${CDN}/projects/gardentag/gt_quote_r.svg`} alt=""/>
                        
                        <div style={{alignItems: "center"}} className='artverse_block_items fdrow'>
                        <img style={{width:"64px", objectFit:"cover"}} loading="lazy" src={`${CDN}/projects/gardentag/gt_avatar1.png`} alt=""/>

                            <p>
                            Flowers and plants make my home more vibrant and improve my mood.                  
                            </p>
                        </div>
                        </div>

                        <div className='artverse_block_column'>

                        <img className='gt_quote_r' loading="lazy" src={`${CDN}/projects/gardentag/gt_quote_r.svg`} alt=""/>
                        
                        <div style={{alignItems: "center"}} className='artverse_block_items fdrow'>
                        <img style={{width:"64px", objectFit:"cover", zIndex:"1"}} loading="lazy" src={`${CDN}/projects/gardentag/gt_avatar4.png`} alt=""/>

                            <p>
                            I grow plants to make my environment better and add some energy to it.                            
                            </p>
                        </div>
                    </div>

                    </div>


                    <p><b>- Challenges in Plant Care:</b> Users often struggle with inconsistent and unreliable plant care information found online, leading to frustration and plant health issues.
                    </p>
                    <div className='fdrow'>

                        <div className='artverse_block_column'>

                        <img className='gt_quote_r' loading="lazy" src={`${CDN}/projects/gardentag/gt_quote_r.svg`} alt=""/>
                        
                        <div style={{alignItems: "center"}} className='artverse_block_items fdrow'>
                        <img style={{width:"64px", objectFit:"cover"}} loading="lazy" src={`${CDN}/projects/gardentag/gt_avatar1.png`} alt=""/>

                            <p>
                            The biggest challenge was finding reliable information; even with tutorials, my plants sometimes still died!                            
                            </p>
                        </div>
                        </div>

                        <div className='artverse_block_column'>

                        <img className='gt_quote_r' loading="lazy" src={`${CDN}/projects/gardentag/gt_quote_r.svg`} alt=""/>
                        
                        <div style={{alignItems: "center"}} className='artverse_block_items fdrow'>
                        <img style={{width:"64px", objectFit:"cover", zIndex:"1"}} loading="lazy" src={`${CDN}/projects/gardentag/gt_avatar4.png`} alt=""/>

                            <p>
                            The weather keeps changing, and my plants almost died when I went on a break.
                            </p>
                        </div>
                    </div>

                    </div>
                        
                    
                    </div>
                    

            </div>

            </div>
            



            <section style={{marginTop:'2rem'}}>
            <div style={{maxWidth:'800px'}} className='artverse_block_column'>
                        <div className='artverse_block_items'>
                            <img style={{width:"64px"}} loading="lazy" src={`${CDN}/projects/gardentag/gt_tech.svg`} alt=""/>
                            <p style={{fontSize:'24px', fontWeight:'600'}}>Exploration of Current Technologies</p>
                            <p >
                            We explored the latest technological advancements relevant to gardening apps to understand how they can be integrated into GardenTag.
                            </p>
                        </div>
                    </div>

            </section>

            <div style={{width:'90%', maxWidth:"1024px"}} className='artverse_bg fdrow'>

            <div className='artverse_block_column'>
                <img className='card-img' loading="lazy" src={`${CDN}/projects/gardentag/art_t1.jpeg`} alt=""/>
                <div className='artverse_block_items'>
                    <p style={{fontSize:'24px', fontWeight:'600'}}>Sensors to Improve Plant Health</p>
                    <p>
                    Advanced sensors can monitor soil moisture, temperature, and light levels to provide real-time data, helping gardeners maintain optimal conditions for plant growth.
                    </p>
                </div>
            </div>

            <div className='artverse_block_column'>
            <img className='card-img' loading="lazy" src={`${CDN}/projects/gardentag/art_t2.jpeg`} alt=""/>
                <div className='artverse_block_items'>
                    <p style={{fontSize:'24px', fontWeight:'600'}}>ML for Enhanced Identification</p>
                    <p>
                    Machine Learning can be used for enhanced plant identification and health diagnostics. ML algorithms can detect diseases, pests, and nutrient deficiencies with high accuracy.
                    </p>
                </div>
            </div>

            <div className='artverse_block_column'>
            <img className='card-img' loading="lazy" src={`${CDN}/projects/gardentag/art_t3.jpeg`} alt=""/>
                <div className='artverse_block_items'>
                    <p style={{fontSize:'24px', fontWeight:'600'}}>Hydroponics</p>
                    <p>
                    Hydroponics technology allows for soil-less plant cultivation, using nutrient-rich water solutions. This method promotes faster plant growth and higher yields while conserving water.
                    </p>
                </div>
            </div>

            </div>



            <section>

                    
                <div className='projectHeader'>
                    <div className='number'>03</div>
                    <h2 className='header'>Define</h2>
                    <p style={{fontSize: '48px'}}>&#x1F4DD;</p>
                </div>



                

                <div className='artverse_block_column'>
                <img className='card-img' loading="lazy" src={`${CDN}/projects/gardentag/affinity.png`} alt=""/>
                <div className='artverse_block_items'>
                    <p style={{fontSize:'24px', fontWeight:'600'}}>Affinity Diagramming</p>
                    <p>
                    We utilized affinity diagramming to categorize and synthesize the interview data. This helped us identify the most critical needs and challenges faced by gardeners.

                    </p>
                </div>
                </div>


                <div className='artverse_block_column'>
                        <div className='artverse_block_items'>
                            <p style={{fontSize:'24px', fontWeight:'600'}}>Problem Statement

</p>
                            <p>
                            How might we provide gardeners with a reliable monitoring device that offers personalized gardening guidance and can detect environmental conditions?                            </p>
                        </div>
                </div>

            </section>


            <p style={{marginTop:"3rem", fontSize:'32px', fontWeight:'600'}}>Key Challenges</p>

            <div style={{width:'90%', maxWidth:"1024px"}} className='artverse_bg fdrow'>

            <div className='artverse_block_column'>
                <div className='artverse_block_items'>
                <img style={{width:"64px"}} loading="lazy" src={`${CDN}/projects/gardentag/gt_pi.svg`} alt=""/>
                    <p style={{fontSize:'24px', fontWeight:'600'}}>Inefficient Plant Identification</p>
                    <p>
                    Users struggle with slow and inaccurate plant identification processes.  
                    </p>
                </div>
            </div>

            <div className='artverse_block_column'>
                <div className='artverse_block_items'>
                <img style={{width:"64px"}} loading="lazy" src={`${CDN}/projects/gardentag/gt_ip.svg`} alt=""/>
                    <p style={{fontSize:'24px', fontWeight:'600'}}>Inconsistent Care Information</p>
                    <p>
                    Gardeners face difficulties finding reliable and consistent plant care advice. 
                    </p>
                </div>
            </div>

            </div>

            <div style={{width:'90%', maxWidth:"1024px"}} className='artverse_bg fdrow'>

            <div className='artverse_block_column'>
                <div className='artverse_block_items'>
                <img style={{width:"64px"}} loading="lazy" src={`${CDN}/projects/gardentag/gt_ic.svg`} alt=""/>
                    <p style={{fontSize:'24px', fontWeight:'600'}}>Lack of Engaging Community Features</p>
                    <p>
                    Gardening apps often fail to foster active and engaging community interactions.
                    </p>
                </div>
            </div>

            <div className='artverse_block_column'>
            
                <div className='artverse_block_items'>
                <img style={{width:"64px"}} loading="lazy" src={`${CDN}/projects/gardentag/gt_iw.svg`} alt=""/>
                    <p style={{fontSize:'24px', fontWeight:'600'}}>Weather Impact on Plant Health
</p>
                    <p>
                    Users need better guidance on managing plant care in response to changing weather conditions
                                        </p>
                </div>
            </div>

            </div>




            <section>
                <div className='projectHeader'>
                    <div className='number'>04</div>
                    <h2 className='header'>Ideation</h2>
                    <p style={{fontSize: '48px'}}>&#x1F4A1;</p>
                </div>
                <p>
                During the ideation phase, we brainstormed innovative solutions to address the key challenges identified. Here are the standout concepts we developed:
                </p>

            </section>

            <div style={{width:'90%', maxWidth:"1024px"}} className='artverse_bg fdrow'>

            <div className='artverse_block_column'>
                <img className='card-img' loading="lazy" src={`${CDN}/projects/gardentag/idea1_u.png`} alt=""/>
                <div className='artverse_block_items'>
                    <p style={{fontSize:'24px', fontWeight:'600'}}>Smart Plant Tag</p>
                    <p>
                    A small, AirTag-sized device that can be inserted into a pot. Users can assign it to specific plants to receive personalized health suggestions and care recommendations based on real-time data.

                    </p>
                </div>
            </div>

            <div className='artverse_block_column'>
            <img className='card-img' loading="lazy" src={`${CDN}/projects/gardentag/idea2_u.png`} alt=""/>
                <div className='artverse_block_items'>
                    <p style={{fontSize:'24px', fontWeight:'600'}}>AI Plant Health Monitor 
</p>
                    <p>
During the ideation phase, we brainstormed innovative solutions to address the key challenges identified. Here are the standout concepts we developed:


                    </p>
                </div>
            </div>

            </div>

            <div style={{width:'90%', maxWidth:"1024px"}} className='artverse_bg fdrow'>

            <div className='artverse_block_column'>
                <img className='card-img' loading="lazy" src={`${CDN}/projects/gardentag/idea3_u.png`} alt=""/>
                <div className='artverse_block_items'>
                    <p style={{fontSize:'24px', fontWeight:'600'}}>Smart Rain Pot</p>
                    <p>
                    Features a built-in rainwater collection system with an automatic retractable roof. It efficiently stores rainwater and protects plants from harsh sunlight and heavy rain, ensuring optimal growth conditions.

                    </p>
                </div>
            </div>

            <div className='artverse_block_column'>
            <img className='card-img' loading="lazy" src={`${CDN}/projects/gardentag/idea4_u.png`} alt=""/>
                <div className='artverse_block_items'>
                    <p style={{fontSize:'24px', fontWeight:'600'}}>Smart Plant Nursery
</p>
                    <p>
                    Employs hydroponics technology to cultivate plants without soil. This innovative nursery system promotes faster growth and healthier plants through a controlled, nutrient-rich environment.

                    </p>
                </div>
            </div>

            </div>

            <section id='tag_img_sequence' style={{marginTop:'2rem'}}>
                    <div className='artverse_block_column'>
                        <div className='artverse_block_items'>
                            <p style={{fontSize:'24px', fontWeight:'600'}}>Concept Evaluation


</p>
                            <p>
                            We presented these ideas to our peers and professor for feedback and then conducted user evaluations to gauge their potential impact and feasibility. Based on the feedback, we decided to focus on the Smart Plant Tag as our primary concept.

</p>
                        </div>
                    </div>

                    <div className='artverse_block_column'>
                        <div className='artverse_block_items'>
                            <p style={{fontSize:'24px', fontWeight:'600'}}>Further Ideation for Smart Plant Tag
</p>
                            <p>
                            With the Smart Plant Tag selected, we delved deeper into its features and functionalities, brainstorming additional aspects to enhance its utility and user experience.
                            </p>
                        </div>
                    </div>


            </section>


            <section>
                <div className='projectHeader'>
                    <div className='number'>05</div>
                    <h2 className='header'>The Product</h2>
                    <p style={{fontSize: '48px'}}>&#x1F680;</p>
                </div>
            </section>


            <canvas style={{marginTop:'8rem'}} className='gardentag_img_sequence' id="gardentag_img_sequence"></canvas> {/* Adjust width and height as needed */}

            <section className='gardentag_product'>
                <div className="gardentag_header">
                    Garden Tag
                </div>
                <div className='gardentag_tagline'>Personalized Plant Care at Your Fingertips</div>

                
            </section>


            <div className='gardentag_sensors'>

                <div className='gardentag_sensors_top'>
                    <div className='gardentag_sensors_item'>
                        <img style={{height:'64px'}} loading="lazy" src={`${CDN}/projects/gardentag/gt_s1.svg`} alt=""/>
                        <h2>Humidity</h2>
                        <p>To ensure optimal humidity for healthy plant.</p>
                    </div>

                    <img className='gardentag_sensors_number' loading="lazy" src={`${CDN}/projects/gardentag/gt_snumber_item.svg`} alt=""/>


                    {/* <div className='gardentag_sensors_number'>
                        <img style={{height:'80px'}} loading="lazy" src={`${CDN}/projects/gardentag/gt_snumber.svg`} alt=""/>
                        <div className='gardentag_sensors_item'>
                            <h2>Sensors</h2>
                        </div>
                    </div> */}

                    

                    <div className='gardentag_sensors_item'>
                        <img style={{height:'64px'}} loading="lazy" src={`${CDN}/projects/gardentag/gt_s2.svg`} alt=""/>
                        <h2>Soil Moisture</h2>
                        <p>To prevent over-watering or under-watering.</p>
                    </div>
                </div>

                <div className='gardentag_sensors_bottom'>
                    <div className='gardentag_sensors_item'>
                        <img style={{height:'64px'}} loading="lazy" src={`${CDN}/projects/gardentag/gt_s3.svg`} alt=""/>
                        <h2>Temperature</h2>
                        <p>To keep plants within their optimal growth temperature.</p>
                    </div>

                    <div className='gardentag_sensors_item'>
                        <img style={{height:'64px'}} loading="lazy" src={`${CDN}/projects/gardentag/gt_s4.svg`} alt=""/>
                        <h2>Light Sensor</h2>
                        <p>To ensure plants receive adequate sunlight.</p>
                    </div>

                    <div className='gardentag_sensors_item'>
                        <img style={{height:'64px'}} loading="lazy" src={`${CDN}/projects/gardentag/gt_s5.svg`} alt=""/>
                        <h2>Soil Nutrient</h2>
                        <p>To guide proper fertilization and ensure balanced nutrition.</p>
                    </div>

                </div>

            </div>

            



        <section>
            <div className='projectHeader'>
                <div className='number'>06</div>
                <h2 className='header'>Prototype</h2>
                <p style={{fontSize: '48px'}}>&#x1F4F1;</p>
            </div>
            <p>
            Created low-fidelity wireframes to outline the new design structure and conducted initial user tests with 5 gardeners for feedback.

            

            <div className='artverse_bg'>

                <div className='artverse_block_column'>
                    <div className='artverse_block_items'>
                        <p style={{fontSize:'24px', fontWeight:'600'}}>Wireframes</p>
                        <p>
                        Created low-fidelity wireframes to outline the new design structure and conducted initial user tests with 5 gardeners for feedback.
                        </p>
                        <img loading="lazy" src={`${CDN}/projects/gardentag/wireframes_u.png`} alt=""/>
                    </div>
                    
                </div>

                <div className='artverse_block_column'>
                    <div className='artverse_block_items'>
                        <p style={{fontSize:'24px', fontWeight:'600'}}>High-Fidelity Prototype</p>
                        <p>
                        With the mid-fi designs validated, we moved on to high-fidelity (hi-fi) mockups. These detailed visual designs brought our VR museum to life, incorporating branding elements, color schemes, and interactive components. The hi-fi prototypes allowed us to simulate the user experience more realistically and fine-tune the visual aesthetics.
                        </p>
                    </div>
                    {/* <img loading="lazy" src={`${CDN}/projects/gardentag/hifi.png`} alt=""/> */}
                </div>
            </div>

            


            </p>

        </section>

        {/* <section>
        <div className='artverse_block_column'>
                <div className='artverse_block_items'>
                    <p style={{fontSize:'24px', fontWeight:'600'}}>High-Fidelity Prototype</p>
                    <p>
                    With the mid-fi designs validated, we moved on to high-fidelity (hi-fi) mockups. These detailed visual designs brought our VR museum to life, incorporating branding elements, color schemes, and interactive components. The hi-fi prototypes allowed us to simulate the user experience more realistically and fine-tune the visual aesthetics.
                    </p>
                </div>
            </div>
        </section> */}
            

        
        <div className='gt_hifi'>

            <div class="gt_hifi_img_wrapper">
                <img className='gt_hifi_img gt_hifi_img_1' loading="lazy" src={`${CDN}/projects/gardentag/gt_hifi_1_u.png`} alt=""/>
                <img className='gt_hifi_img' loading="lazy" src={`${CDN}/projects/gardentag/gt_hifi_2_u.png`} alt=""/>
                <img className='gt_hifi_img gt_hifi_img_2' loading="lazy" src={`${CDN}/projects/gardentag/gt_hifi_3_u.png`} alt=""/>
                <img className='gt_hifi_img' loading="lazy" src={`${CDN}/projects/gardentag/gt_hifi_4_u.png`} alt=""/>
                <img className='gt_hifi_img gt_hifi_img_1' loading="lazy" src={`${CDN}/projects/gardentag/gt_hifi_5_u.png`} alt=""/>
            </div>

        </div>




        <iframe class='video' src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F0tQGP8UAe7LdpJGK61rbRt%2FIXDS-734%3Fpage-id%3D1247%253A2902%26node-id%3D1247-5234%26viewport%3D932%252C768%252C0.14%26t%3DSvpO2IVihzegJ4B9-1%26scaling%3Dscale-down%26content-scaling%3Dfixed%26starting-point-node-id%3D1247%253A5344%26show-proto-sidebar%3D1" allowfullscreen></iframe>


        <section>
            <div className='projectHeader'>
                <div className='number'>07</div>
                <h2 className='header'>Technologies Implemented</h2>
                <p style={{fontSize: '48px'}}>&#x1F528;</p>
            </div>

        </section>

        <div className='artverse_bg fdcolumn'>

        <div className='artverse_block'>
            <img className='card-img' loading="lazy" src={`${CDN}/projects/artverse/Fimga_card_logo.png`} alt=""/>
            <div className='artverse_block_items'>
                <p style={{fontSize:'32px', fontWeight:'600'}}>Figma</p>
                <p>
                    <b>Design & Prototyping: </b>
                    Utilized Figma to create and iterate on wireframes and high-fidelity designs for GardenTag.
                <br/>
                Figma’s collaborative features made it easier to gather feedback and refine the user interface.
                </p>
            </div>
        </div>

        <div className='artverse_block'>
        <img className='card-img' loading="lazy" src={`${CDN}/projects/gardentag/notion_card_logo.png`} alt=""/>
            <div className='artverse_block_items'>
                <p style={{fontSize:'32px', fontWeight:'600'}}>Notion</p>
                <p>
                    <b>Documentation & Project Management: </b>
                Notion was used to document the design process, organize research findings, and manage project tasks.        
                <br/>
                Its flexible structure helped in maintaining clear and comprehensive records of our progress.            
                </p>
            </div>
        </div>

        <div className='artverse_block'>
        <img className='card-img' loading="lazy" src={`${CDN}/projects/gardentag/spline_card_logo.png`} alt=""/>
            <div className='artverse_block_items'>
                <p style={{fontSize:'32px', fontWeight:'600'}}>Spline</p>
                <p>
                <b>3D Design: </b> Leveraged Spline to create 3D designs for GardenTag, adding an extra dimension to our visual assets.                     
                <br/>
                Spline allowed us to animate and visualize 3D elements that enhanced the app’s interactive features.
                </p>
            </div>
        </div>

        </div>


        <section>
            <div className='projectHeader'>
                <div className='number'>08</div>
                <h2 className='header'>My Learnings & Takeaways</h2>
                <p style={{fontSize: '48px'}}>&#x1F4C8;</p>
            </div>

        </section>
        
        <div style={{width:'90%', maxWidth:"1024px", marginBottom:'5rem'}} className='artverse_bg fdcolumn'>

            <div className='fdrow'>
                <div className='artverse_block_column'>
                    <img className='card-img-l' loading="lazy" src={`${CDN}/projects/gardentag/gt_l1.png`} alt=""/>
                    <div className='artverse_block_items'>
                        <p style={{fontSize:'24px', fontWeight:'600'}}>User-Centered Design is Essential</p>
                        <p>
                        Involving users throughout the project was crucial. Their continuous feedback and insights guided the design process, ensuring that the final product effectively met their needs and preferences.
                        </p>
                    </div>
                </div>

                <div className='artverse_block_column'>
                <img className='card-img-l' loading="lazy" src={`${CDN}/projects/gardentag/gt_l2.png`} alt=""/>
                    <div className='artverse_block_items'>
                        <p style={{fontSize:'24px', fontWeight:'600'}}>Mastering New Tools
</p>
                        <p>
                        Learning and utilizing Spline, a 3D design tool, was a significant achievement. It expanded my skillset and provided deeper insights into how 3D elements can enhance user understanding and interaction.                        
                        </p>
                    </div>
                </div>

            </div>

            <div className='fdrow'>

                <div className='artverse_block_column'>
                <img className='card-img-l' loading="lazy" src={`${CDN}/projects/gardentag/gt_l3.webp`} alt=""/>
                    <div className='artverse_block_items'>
                        <p style={{fontSize:'24px', fontWeight:'600'}}>Heuristic Evaluation
</p>
                        <p>
                        Implementing heuristic evaluation helped identify usability issues in adherence to Jakob Nielsen’s 10 Usability Heuristics. This method was instrumental in refining the design to ensure a user-friendly interface.
</p>
                    </div>

                    

                </div>

                <div className='artverse_block_column'>
                <img className='card-img-l' loading="lazy" src={`${CDN}/projects/gardentag/gt_l4.png`} alt=""/>
                    <div className='artverse_block_items'>
                        <p style={{fontSize:'24px', fontWeight:'600'}}>Iterative Design Process

</p>
                        <p>
                        Continuous testing and iteration were vital in refining the user experience. Each round of usability testing provided actionable feedback that improved the design’s effectiveness and user satisfaction.
</p>
                    </div>
                </div>

                

            </div>
            
        
        </div>


        <section>
            <div className='projectHeader'>
                <div className='number'>09</div>
                <h2 className='header'>Appendix</h2>
                <p style={{fontSize: '48px'}}>&#128206;</p>
            </div>

        </section>

        <div style={{width:'90%', maxWidth:"1024px", marginBottom:"10rem"}} className='artverse_bg fdrow'>

            <div className='gt_appendix'>
                    <a target="_blank" rel="noreferrer" href="https://sarathnekuri.notion.site/IXDS-734-8e781038af6e433bba09025e0ea9166c">
                        View File
                    </a>
                <img loading="lazy" src={`${CDN}/projects/gardentag/gt_a1.png`} alt=""/>
            </div>

            <div className='gt_appendix'>
                    <a target="_blank" rel="noreferrer" href="https://my.spline.design/untitled-67edb43f3d2db3c049fdb41e963aed2f/">
                        View File
                    </a>
                <img loading="lazy" src={`${CDN}/projects/gardentag/gt_a2.png`} alt=""/>
            </div>

           

        </div>



        </motion.div>
        </div>
        
        );
    
}
 
export default GardenTag;