import React,{useEffect} from 'react';
import {NavLink, Link} from 'react-router-dom';
import gsap from 'gsap';
import {CDN} from '../config';
import { HashLink } from 'react-router-hash-link';


function NavBar() {
    

   
    useEffect(()=>{
        const menuBar = document.querySelector(".menubar");
        const menu = document.querySelector(".menu");
        const body = document.querySelector("body");


        //if(menuBar)
        menuBar.addEventListener("click", function () {
            menu.classList.toggle("slide");
            body.classList.toggle("stop-scrolling");
            document.getElementById("Rectangle_136").classList.toggle("r136");
            document.getElementById("Rectangle_137").classList.toggle("r137");
            document.getElementById("Rectangle_138").classList.toggle("r138");
            
            gsap.from(".work", { duration: 0.5, delay:0.5, opacity:0, y:80})
            gsap.from(".resume", { duration: 0.5, delay:0.7, opacity:0, y:80})
            gsap.from(".about", { duration: 0.5, delay:0.9, opacity:0, y:80})
            gsap.from(".courseProjects", { duration: 0.5, delay:1.1, opacity:0, y:80})
            // gsap.from(".contactButton", { duration: 0.5, delay:1.3, opacity:0, y:80})
        })

        const work = document.querySelector(".work"); 
        const about = document.querySelector(".about");
        const courseProjects = document.querySelector(".courseProjects");

        work.addEventListener("click",function(){
            work.classList.add("active-menu"); 
            courseProjects.classList.remove("active-menu"); 
            about.classList.remove("active-menu");
            if(window.screen.width < 1024){
                setTimeout(function(){
                    menu.classList.remove("slide");
                    body.classList.toggle("stop-scrolling");
                    document.getElementById("Rectangle_136").classList.toggle("r136");
                    document.getElementById("Rectangle_137").classList.toggle("r137");
                    document.getElementById("Rectangle_138").classList.toggle("r138");
                },1000)
                
            }
        })

        const tease = document.getElementById("teaseButton");

        tease.addEventListener("mousemove", function(e){
            const position = tease.getBoundingClientRect();

            const x = e.pageX - position.left - position.width /2;
            const y = e.pageY - position.top - position.height /2;

            tease.style.transform = "translate(" + x*1 + "px," + y*1 + "px)";
        })

        tease.addEventListener("mouseout", function(e){ 

            tease.style.transform = "translate(0,0)";
        }) 

        about.addEventListener("click",function(){
            work.classList.remove("active-menu"); 
            courseProjects.classList.remove("active-menu"); 
            about.classList.add("active-menu");
            if(window.screen.width < 1024){
                setTimeout(function(){
                    menu.classList.remove("slide");
                    body.classList.toggle("stop-scrolling");
                    document.getElementById("Rectangle_136").classList.toggle("r136");
                    document.getElementById("Rectangle_137").classList.toggle("r137");
                    document.getElementById("Rectangle_138").classList.toggle("r138");
                },1000)
            }
        })



        courseProjects.addEventListener("click",function(){
            work.classList.remove("active-menu"); 
            about.classList.remove("active-menu"); 
            courseProjects.classList.add("active-menu");
            if(window.screen.width < 1024){
                setTimeout(function(){
                    menu.classList.remove("slide");
                    body.classList.toggle("stop-scrolling");
                    document.getElementById("Rectangle_136").classList.toggle("r136");
                    document.getElementById("Rectangle_137").classList.toggle("r137");
                    document.getElementById("Rectangle_138").classList.toggle("r138");
                },1000)
            }
        })



        const logo = document.querySelector("#logo");
        if(logo){
            logo.addEventListener("mouseenter", function(){
                document.getElementById("navlogo_right").classList.add("navlogo_rotate")
                document.getElementById("navlogo_top").classList.add("navlogo_rotate")
                document.getElementById("navlogo_bottom").classList.add("navlogo_rotate")
                
            });
            logo.addEventListener("mouseleave", function(){
                setTimeout(function () {
                    document.getElementById("navlogo_right").classList.remove("navlogo_rotate")
                    document.getElementById("navlogo_top").classList.remove("navlogo_rotate")
                    document.getElementById("navlogo_bottom").classList.remove("navlogo_rotate")
                },2000)
            });
        }
        
    

    },[])

    return (
        <div className="navbar-container">
            <div className="navbar" id='navbar'>
                
                <Link aria-label="SC Logo" className="logoC" to="/">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="40" viewBox="0 0 48 40" fill="none">
                        <g id='logo' clipPath="url(#clip0_605_3686)">
                        <rect width="48" height="40" fill="white"/>
                        <path id="navlogo_right" className='navlogo_right' fillRule="evenodd" clipRule="evenodd" d="M47.4569 16.8427L37.8076 26.5541L48.0003 35.6082C45.4756 38.3072 41.8441 40 37.8082 40C30.1821 40 24 33.9558 24 26.5C24 19.0442 30.1821 13 37.8082 13C41.563 13 44.9677 14.4652 47.4569 16.8427Z" fill="#0D0E13"/>
                        <path id="navlogo_top" className='navlogo_top' fillRule="evenodd" clipRule="evenodd" d="M23.4569 3.84272L13.8076 13.5541L24.0003 22.6082C21.4756 25.3072 17.8441 27 13.8082 27C6.18214 27 0 20.9558 0 13.5C0 6.04416 6.18214 0 13.8082 0C17.563 0 20.9677 1.46521 23.4569 3.84272Z" fill="#0D0E13"/>
                        <path id="navlogo_bottom" className='navlogo_bottom' fillRule="evenodd" clipRule="evenodd" d="M1.54364 16.8427L11.1929 26.5541L1.00018 35.6082C3.52493 38.3072 7.15641 40 11.1923 40C18.8183 40 25.0005 33.9558 25.0005 26.5C25.0005 19.0442 18.8183 13 11.1923 13C7.43753 13 4.0328 14.4652 1.54364 16.8427Z" fill="#0D0E13"/>
                        </g>''
                    </svg>

                </Link>

                <div className="menubar">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                        <g id="menu-1" transform="translate(-125 -125)">
                            <rect id="Rectangle_136" data-name="Rectangle 136" width="36" height="3" transform="translate(131.838 139.593)" fill="#0D0E13"/>
                            <rect id="Rectangle_137" data-name="Rectangle 137" width="36" height="3" transform="translate(131.838 148.593)" fill="#0D0E13"/>
                            <rect id="Rectangle_138" data-name="Rectangle 138" width="36" height="3" transform="translate(131.838 157.593)" fill="#0D0E13"/>
                        </g>
                    </svg>
                </div>


                <ul className="menu">
                    <li><NavLink exact activeClassName="active-menu " className="menu-bg work nocursor" to="/">Home</NavLink></li>
                    <li><NavLink exact activeClassName="active-menu " className="menu-bg work nocursor" to="/interactions">Interactions</NavLink></li>
                    <li><NavLink exact activeClassName="active-menu " className="menu-bg courseProjects nocursor" to="/course-projects">Projects</NavLink></li>
                    <li><a className="menu-bg resume nocursor" href = {`${CDN}/Sarath_Chand_Nekuri-Resume.pdf`} target = "_blank" rel="noreferrer" >Resume</a></li>
                    <li><NavLink exact activeClassName="active-menu " className="menu-bg about nocursor" to="/about">About Me</NavLink></li>
                    {/* <li><NavLink exact activeClassName="active-menu" className="menu-bg resources" to="/resources">Resources</NavLink></li> */}
                    <span className='desktop-d-none'>
                        <li className="contactButton teaseButton"><HashLink exact to="/home#contactPager">Contact</HashLink></li>
                    </span>
                </ul>
                <ul className='mobile-d-none'>
                    <li id='teaseButton' className="contactButton teaseButton"><HashLink exact to="/home#contactPager">Contact</HashLink></li>
                </ul>

            </div>
            
        </div>
        
    );
}

 
export default NavBar;