import React,{useEffect} from "react";
import CursorFollower from '../components/cursorFollower';
import {motion} from 'framer-motion';
import VanillaTilt from "vanilla-tilt";
import { HashLink } from 'react-router-hash-link';

import { CDN } from '../config';


function About() {

    useEffect(()=>{ 
        const element = document.querySelector(".my-image");
        VanillaTilt.init(element);
        // element.addEventListener("tiltChange", callback);
    })

    return (
        <div> <CursorFollower/>

        <motion.div
        animate={{opacity:1,  translateY:"0px"}}
        initial={{opacity:0, translateY:"50px"}}
        transition={{ duration: 1.5 }}
        exit={{opacity:0, translateY:"50px"}}
         className="about-container">
            
            <div className="about-text">
                <h1>Hello Again!👋</h1>
                <p style={{marginTop:"2rem"}}>I am Sarath Chand Nekuri, currently graduating in <a target="_blank"  href="https://www.scad.edu/academics/programs/interactive-design-and-game-development" rel="noreferrer">Interactive Design</a>  at <a target="_blank"  href="https://www.scad.edu/" rel="noreferrer">Savannah College of Art and Design</a>.</p>
                <p>My journey into design began during high school when I discovered my passion for editing photos on Adobe Photoshop. Despite pursuing a degree in Electronics and Communication Engineering, my enthusiasm for design led me to take the reins as the Design Team Head for our college's technical fest, VASHISHT.</p>
                <p>Following graduation, I delved into a <a target="_blank"  href="https://www.coursera.org/professional-certificates/google-ux-design" rel="noreferrer">UX course</a> by Google, where I immersed myself in hands-on projects, honing my skills and securing an internship opportunity.</p>
                <p>Beyond design, you'll often find me capturing moments through photography, or indulging in a new Netflix series or music playlist.</p>
                <p> Feel free to <a><HashLink exact to="/home#socialNetworks">Connect with me</HashLink></a></p>
            </div>

            <div className="my-image-c">
                <div data-tilt className="my-image">
                    <img className="my-imaged" src={`${CDN}/images/images/scimg_back_3.png`} alt="My_image" />
                    <img className="my-imaged-back" src={`${CDN}/images/images/scimg_front_2.png`} alt="My_image" />
                </div>
            </div>
            
        </motion.div>

        </div> 
       
     );
}

export default About
