import React, {useEffect } from 'react';


import { Link } from 'react-router-dom';

import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
import {motion} from 'framer-motion';
import CursorFollower from '../components/cursorFollower';
import ReactGA from 'react-ga';

import { CDN } from '../config';


import Slideshow from "../components/slideshow";


function Artverse() {

    const slides_midfi = [
        { image: `${CDN}/projects/artverse/homepage_midfi.png`, caption: 'ArtVerse Homepage' },
        { image: `${CDN}/projects/artverse/topartists_midfi.png`, caption: 'Top Artists' },
        { image: `${CDN}/projects/artverse/360_artview_midfi.png`, caption: '360 Degree view page of Artpiece' },
        { image: `${CDN}/projects/artverse/artpiece_midfi.png`, caption: 'Art work description page' },
        { image: `${CDN}/projects/artverse/gallery_midfi.png`, caption: 'My Gallery page' }
    ];

    const slides_hifi = [
        { image: `${CDN}/projects/artverse/homepage_hifi.png`, caption: 'ArtVerse Homepage' },
        { image: `${CDN}/projects/artverse/topartists_hifi.png`, caption: 'Top Artists' },
        { image: `${CDN}/projects/artverse/360_artview_hifi.png`, caption: '360 Degree view of Artpiece' },
        { image: `${CDN}/projects/artverse/artpiece_hifi.png`, caption: 'Art work description page with Artist Introduction' },
        { image: `${CDN}/projects/artverse/gallery_hifi.png`, caption: 'My Gallery Page' }
    ];

    const slides_VR = [
        // { image: `${CDN}/projects/artverse/milly_vr.jpg`, caption: 'Milly Wen Prototyping on ShapesXR' },
        { image: `${CDN}/projects/artverse/MyGallery.gif`, caption: 'My Gallery' },
        { image: `${CDN}/projects/artverse/TopArtists.gif`, caption: 'Top Art pieces with description' }
    ];
     
    return (
        <div>
            {/* <CursorFollower/> */}

        <motion.div 
        animate={{opacity:1,  translateY:"0px"}}
        initial={{opacity:0, translateY:"50px"}}
        transition={{ duration: 1.5 }}
        exit={{opacity:0, translateY:"50px"}}
        className="homer artverse">

            <section style={{marginTop:'0rem'}} className='top'>
            
                <img style={{width:"80px"}} loading="lazy" src={`${CDN}/projects/artverse/av_logo_u.png`} alt=""/>
                            
                <h1>ArtVerse – Bridging Gaps in Art Education</h1>
                <p>
                ArtVerse is a VR museum that revolutionizes art education by making it accessible and interactive, breaking down barriers like cost and location to enhance global cultural appreciation.
                </p>

                <ul>
                    <li className='list-block'>
                        <p>TIMELINE</p>
                        <p>Mar - May 2024</p>
                    </li>
                    <li className='list-block'>
                        <p>My Role</p>
                        <p>Research, IA, UI, UX, Prototyping</p>
                    </li>
                    <li className='list-block'>
                        <p>Tools</p>
                        <p>Figma, ShapesXR, Unity</p>
                    </li>
                </ul>

                <ul>
                    <li style={{width: '100%'}} className='list-block'>
                        <p>Team</p>
                        <p>Mobei Qian, Monan Qian, Qianzi Wen and Sarath Chand Nekuri</p>
                    </li>
                </ul>
                
                
            </section>

            
            <iframe className='video' src="https://www.youtube.com/embed/OvaoyWlBuIU?si=BxuWHnXRC494haaZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
           
            <section>
                <div className='projectHeader'>
                    <div className='number'>01</div>
                    <h2 className='header'>The Background</h2>
                    <p style={{fontSize: '48px'}}>&#x1F4DA;</p>
                </div>
                <p>
                Art education is essential for fostering creativity, critical thinking, and cultural awareness. However, the field faces significant challenges, both in terms of accessibility and engagement. Despite its importance, many people, especially younger generations, struggle to connect with and understand art. This disconnect is evident in several key statistics:

                <div className='artverse_bg'>

                    <div className='artverse_block'>
                        <img loading="lazy" src={`${CDN}/projects/artverse/art_2.jpeg`} alt=""/>
                        <div className='artverse_block_items'>
                            <p style={{fontSize:'24px', fontWeight:'600'}}>Lack of Recognition and Understanding</p>
                            <p>
                                A 2017 survey found that 58% of millennials couldn't identify key artistic movements like Impressionism, Cubism, or Surrealism. This highlights a significant gap in basic art knowledge, hindering art appreciation across generations.
                            </p>
                        </div>
                    </div>

                    <div className='artverse_block'>
                        <img loading="lazy" src={`${CDN}/projects/artverse/art_1.jpeg`} alt=""/>
                        <div className='artverse_block_items'>
                            <p style={{fontSize:'24px', fontWeight:'600'}}>Perception of Art</p>
                            <p>
                                Contrary to the belief that art is "pretentious," only 1 in 14 people feel this way. This indicates that the main barriers to art engagement are likely education and exposure, not attitudes towards art itself.
                            </p>
                        </div>
                    </div>

                    <div className='artverse_block'>
                        <img loading="lazy" src={`${CDN}/projects/artverse/art_3.jpeg`} alt=""/>
                        <div className='artverse_block_items'>
                            <p style={{fontSize:'24px', fontWeight:'600'}}>Impact of Digital Technologies</p>
                            <p>
                                Digital and VR technologies are transforming education. A 2020 Stanford study found that VR can boost student engagement and retention by 30% compared to traditional methods. VR's immersive experience can make art more accessible, especially for those unable to visit physical museums or galleries.
                            </p>
                        </div>
                    </div>




                </div>

                </p>

            </section>

            
            <section>
                <div className='projectHeader'>
                    <div className='number'>02</div>
                    <h2 className='header'>Empathize</h2>
                    <p style={{fontSize: '48px'}}>&#x1F50D;</p>
                </div>
                <p>
                Our approach began with deep dives into domain research and gathering user insights. We aimed to understand the barriers people face in accessing and appreciating art and how technology can help bridge these gaps.
                </p>

            </section>

            <div className='artverse_bg fdcolumn'>


            <div style={{marginBottom:'2rem'}} className='fdrow'>
                    <div style={{maxWidth:'320px'}} className='artverse_block_column'>
                        <div className='artverse_block_items'>
                            <img style={{width:"64px"}} loading="lazy" src={`${CDN}/projects/artverse/art_interview.svg`} alt=""/>
                            <p style={{fontSize:'24px', fontWeight:'600'}}>User Interviews</p>
                            <p>
                            Conducted detailed interviews with art enthusiasts, students, and educators to gain insights into their experiences and expectations. 
                            </p>
                        </div>
                    </div>

                    <div className='fdcolumn'>
                        <p style={{fontSize:'24px', fontWeight:'600'}}>Participant Quotations</p>

                        <div className='artverse_block_column'>
                        <div className='artverse_block_items'>
                            <p>
                            Utilize technology to make art experiences more interactive and appealing, even for those not initially interested.                            </p>
                        </div>
                        </div>

                        <div className='artverse_block_column'>
                        <div className='artverse_block_items'>
                            <p>
                            Introduce art programs at lower education levels to cultivate interest and understanding from a young age.                            
                            </p>
                        </div>
                        </div>
                    </div>

                    

            </div>

            <div style={{marginBottom:'2rem'}} className='fdrow'>
                    <div style={{maxWidth:'320px'}} className='artverse_block_column'>
                        <div className='artverse_block_items'>
                            <img style={{width:"64px"}} loading="lazy" src={`${CDN}/projects/artverse/art_survey.svg`} alt=""/>
                            <p style={{fontSize:'24px', fontWeight:'600'}}>Surveys</p>
                            <p>
                            We surveyed 40 individuals to gather quantitative data on art awareness and barriers to engagement. The majority (71%) were Gen Z.


</p>
                        </div>
                    </div>

                    <div className='fdcolumn'>
                        <p style={{fontSize:'24px', fontWeight:'600'}}>Survey Report</p>


                        <div className='artverse_block_column'>
                        <div className='artverse_block_items'>
                            <p>
                            Only <span style={{fontSize:"20px", fontWeight:'600'}}>42%</span> of respondents could identify major artistic movements like Renaissance and Impressionism, indicating a significant gap in basic art knowledge among younger generations.                            
                            </p>
                        </div>
                        </div>
                        <div className='artverse_block_column'>
                        <div className='artverse_block_items'>
                            <p>
                            <span style={{fontSize:"20px", fontWeight:'600'}}>53%</span> of respondents cited lack of time, perception of art as too complex or pretentious, and limited access to museums as their primary barriers to engaging with art. This highlights the need for accessible and simplified art education tools.
                            </p>
                        </div>
                        </div>
                    </div>

            </div>


            <div style={{marginBottom:'2rem'}} className='fdrow'>
                    <div style={{maxWidth:'320px'}} className='artverse_block_column'>
                        <div className='artverse_block_items'>
                            <img style={{width:"64px"}} loading="lazy" src={`${CDN}/projects/artverse/art_research_paper.svg`} alt=""/>
                            <p style={{fontSize:'24px', fontWeight:'600'}}>Research Paper</p>
                            <p>
                            Reviewed academic and industry literature on art education trends, digital engagement, and the impact of immersive technologies.
                            </p>
                        </div>
                    </div>

                    <div className='fdcolumn'>
                        <p style={{fontSize:'24px', fontWeight:'600'}}>Key Takeaways</p>


                        <div className='artverse_block_column'>
                        <div className='artverse_block_items'>
                            <p>
                            <b>Virtual Reality in Art Education (2021): </b>
                            <br/>
                            VR enhances art education by providing immersive, barrier-free learning experiences 
                            <a target="_blank" rel="noreferrer" href="https://journals.sagepub.com/doi/full/10.1177/02762374211011740?casa_token=APdJUEbjCtIAAAAA%3Aod8wi9VC1UH4XwmHM2om_qxnapWkHofnEthQrTs6FYIf0O1jmfsISB8kzEW2tqdtHJCcZcgJIK4g"> [source]</a> .
                            </p>
                        </div>
                        </div>
                        <div className='artverse_block_column'>
                        <div className='artverse_block_items'>
                            <p>
                            <b>Why People Visit Museums and Why They Don’t (2020): </b>
                            <br/>
                            Museums attract visitors seeking cultural and educational value. However, perceptions of museums as intimidating highlight the need for engaging and accessible digital platforms
                            <a target="_blank" rel="noreferrer" href="https://books.google.co.in/books?hl=en&lr=&id=TKE_EAAAQBAJ&oi=fnd&pg=PR7&dq=why+people+visit+museums&ots=kNzbLSjrDx&sig=nffp0UIT4ZqClTelBy1EKLUafew&redir_esc=y#v=onepage&q=why%20people%20visit%20museums&f=false"> [source]</a> .

                            </p>
                        </div>
                        </div>
                    </div>

            </div>

            </div>
            
            <section>
                <div className='projectHeader'>
                    <div className='number'>03</div>
                    <h2 className='header'>Define</h2>
                    <p style={{fontSize: '48px'}}>&#x1F4DD;</p>
                </div>

                <div className='artverse_block_column'>
                        <div className='artverse_block_items'>
                            <p style={{fontSize:'24px', fontWeight:'600'}}>Problem Statement

</p>
                            <p>
                            How might we make art education more accessible, engaging, and inclusive for a diverse audience through digital and immersive technologies?
                            </p>
                        </div>
                    </div>

            </section>


            <p style={{marginTop:"3rem", fontSize:'32px', fontWeight:'600'}}>User Needs</p>

            <div style={{width:'90%', maxWidth:"1024px"}} className='artverse_bg fdrow'>

            <div className='artverse_block_column'>
                <img className='card-img' loading="lazy" src={`${CDN}/projects/artverse/un1.jpeg`} alt=""/>
                <div className='artverse_block_items'>
                    <p style={{fontSize:'24px', fontWeight:'600'}}>Visibility and Interaction:</p>
                    <p>
                    Users need better access to information about art and events.
                    <br/>
                    They seek more interactive and engaging ways to learn about art.
                    </p>
                </div>
            </div>

            <div className='artverse_block_column'>
            <img className='card-img' loading="lazy" src={`${CDN}/projects/artverse/un2.jpeg`} alt=""/>
                <div className='artverse_block_items'>
                    <p style={{fontSize:'24px', fontWeight:'600'}}>Affordability and Early Exposure:
</p>
                    <p>
                    Lowering costs of tickets and museum access can attract more participants.
                    <br/>
                    Early art education can spark lasting interest and appreciation.
                    </p>
                </div>
            </div>

            </div>



            <p style={{marginTop:"3rem", fontSize:'32px', fontWeight:'600'}}>Focus Areas</p>

            <div style={{width:'90%', maxWidth:"1024px"}} className='artverse_bg fdrow'>

            <div className='artverse_block_column'>
                
                <div className='artverse_block_items'>
                <img style={{width:"64px"}} loading="lazy" src={`${CDN}/projects/artverse/fa1.svg`} alt=""/>
                
                    <p style={{fontSize:'24px', fontWeight:'600'}}>Immersive Learning</p>
                    <p>
                    Use VR and interactive tech to create engaging art experiences.
                                        </p>
                </div>
            </div>

            <div className='artverse_block_column'>
                <div className='artverse_block_items'>
                <img style={{width:"64px"}} loading="lazy" src={`${CDN}/projects/artverse/fa2.svg`} alt=""/>
                
                    <p style={{fontSize:'24px', fontWeight:'600'}}>Connection through Art</p>
                    <p>
                    Fostering a sense of belonging and connection among individuals.
                    </p>
                </div>
            </div>

            <div className='artverse_block_column'>
                <div className='artverse_block_items'>
                <img style={{width:"64px"}} loading="lazy" src={`${CDN}/projects/artverse/fa3.svg`} alt=""/>
                
                    <p style={{fontSize:'24px', fontWeight:'600'}}>Affordable Access</p>
                    <p>
                    Make museum visits more affordable and inclusive.
                    </p>
                </div>
            </div>

            </div>

            <section>
                <div className='projectHeader'>
                    <div className='number'>04</div>
                    <h2 className='header'>Ideation</h2>
                    <p style={{fontSize: '48px'}}>&#x1F4A1;</p>
                </div>
                <p>
                In the Ideation phase, our team embarked on a journey to generate and refine ideas aimed at making art education more accessible, engaging, and inclusive. This phase involved exploring various approaches and innovative solutions to address the challenges we identified during our research and Define phase.
                
                <div className='artverse_bg'>

                    <div className='artverse_block_column'>
                        <img className='h320' loading="lazy" src={`${CDN}/projects/artverse/art_5.jpeg`} alt=""/>
                        <div className='artverse_block_items'>
                            <p style={{fontSize:'24px', fontWeight:'600'}}>Brainstorming Sessions</p>
                            <p>
                            We conducted brainstorming sessions to generate a wide range of ideas. Each team member contributed unique perspectives, leading to diverse concepts.
                            <br/>
                            Techniques like mind mapping and "How Might We" questions helped us think outside the box.
                            </p>
                        </div>
                    </div>

                    <div className='artverse_block_column'>
                        <img className='h320'  loading="lazy" src={`${CDN}/projects/artverse/art_4.jpeg`} alt=""/>
                        <div className='artverse_block_items'>
                            <p style={{fontSize:'24px', fontWeight:'600'}}>Exploring Educational Technologies</p>
                            <p>
                            We researched current trends in educational technology, focusing on digital and virtual solutions.
                            <br/>
                            One promising idea was using Virtual Reality (VR) to create an immersive learning environment, making art exploration more interactive and engaging.
                            </p>
                        </div>
                    </div>

                    <div className='artverse_block_column'>
                        <img className='h320'  loading="lazy" src={`${CDN}/projects/artverse/art_6.jpeg`} alt=""/>
                        <div className='artverse_block_items'>
                            <p style={{fontSize:'24px', fontWeight:'600'}}>Concept Development</p>
                            <p>
                            From our sessions, two standout features emerged:
                            <br/>
                            My Gallery: Allows users to curate personal virtual galleries tailored to their interests.
                            <br/>
                            Artist Avatar Introduction: Interactive avatars provide in-depth insights into artists' lives and works, adding a personal touch to the learning experience.
                            </p>
                        </div>
                    </div>

                    <div className='artverse_block_column'>
                        <img className='h320'  loading="lazy" src={`${CDN}/projects/artverse/art_7.jpg`} alt=""/>
                        <div className='artverse_block_items'>
                            <p style={{fontSize:'24px', fontWeight:'600'}}>Wireframing</p>
                            <p>
                            Created low-fidelity sketches and wireframes to outline the basic structure and user flow.
                            <br/>
                            Tools like Figma helped us visualize and iterate on our designs quickly.
                            </p>
                        </div>
                    </div>



                    

                </div>

                </p>

            </section>


            <section>
                <div className='projectHeader'>
                    <div className='number'>05</div>
                    <h2 className='header'>Prototype</h2>
                    <p style={{fontSize: '48px'}}>&#x1F4F1;</p>
                </div>
                <p>
                In the Prototype phase, we transitioned from conceptual ideas to tangible, interactive models of our solution. This stage was crucial for visualizing how our initial ideas would function in a real-world context and provided the foundation for subsequent user testing.
                

                <div className='artverse_bg'>

                    <div className='artverse_block_column'>
                        <div className='artverse_block_items'>
                            <p style={{fontSize:'24px', fontWeight:'600'}}>Creating Mid-Fidelity Prototypes</p>
                            <p>
                            We began by refining our lo-fi sketches into mid-fidelity wireframes using Figma. These wireframes included more detailed layouts and basic functionality, focusing on user flow and interface structure without the distraction of full design aesthetics.
                            </p>
                        </div>
                        <Slideshow slides={slides_midfi} />
                    </div>

                    <div className='artverse_block_column'>
                        <div className='artverse_block_items'>
                            <p style={{fontSize:'24px', fontWeight:'600'}}>High-Fidelity Prototype</p>
                            <p>
                            With the mid-fi designs validated, we moved on to high-fidelity (hi-fi) mockups. These detailed visual designs brought our VR museum to life, incorporating branding elements, color schemes, and interactive components. The hi-fi prototypes allowed us to simulate the user experience more realistically and fine-tune the visual aesthetics.
                            </p>
                        </div>
                        <Slideshow slides={slides_hifi} />
                    </div>

                    <div className='artverse_block_column'>
                        <div className='artverse_block_items'>
                            <p style={{fontSize:'24px', fontWeight:'600'}}>Impact of Digital Technologies</p>
                            <p>
                            Recognizing the importance of a truly immersive experience, we moved from 2D wireframes to 3D modeling and VR prototyping using ShapesXR. This shift was pivotal in capturing the spatial dynamics and interactivity required for a virtual museum setting.
                            </p>
                        </div>
                        <Slideshow slides={slides_VR} />
                    </div>



                    

                </div>

               


                </p>

            </section>

            
            <iframe class='video' src="
            https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fyeib8RBe40pOeMSK1J0ya2%2FIXDS-724---Rainbow-%25F0%259F%258C%2588%3Fpage-id%3D179%253A4%26node-id%3D347-30%26viewport%3D-447%252C796%252C0.23%26scaling%3Dcontain%26content-scaling%3Dfixed%26starting-point-node-id%3D347%253A30%26hotspot-hints%3D0
            " allowfullscreen></iframe>

            {/* <section>
                <div className='projectHeader'>
                    <div className='number'>06</div>
                    <h2 className='header'>Conclusion</h2>
                    <p style={{fontSize: '48px'}}>&#129488;</p>
                </div>
                <p>
                    Think back on the last time you went to a museum... <br/>
                    How did you feel after your visit?<br/>
                    feel exhausted?
                    feel like you didn't understand most of the work?
                    are you able to visit it again anytime you want?
                </p>

            </section> */}
            

            <section>
                <div className='projectHeader'>
                    <div className='number'>06</div>
                    <h2 className='header'>Technologies Implemented</h2>
                    <p style={{fontSize: '48px'}}>&#x1F528;</p>
                </div>

            </section>

            <div style={{width:'90%', maxWidth:"1024px"}} className='artverse_bg fdrow'>

            <div className='artverse_block_column'>
                <img className='card-img' loading="lazy" src={`${CDN}/projects/artverse/Fimga_card_logo.png`} alt=""/>
                <div className='artverse_block_items'>
                    <p style={{fontSize:'32px', fontWeight:'600'}}>Figma</p>
                    <p>
                    Design & Prototyping: Used to create detailed wireframes and high-fidelity screens.
                    </p>
                </div>
            </div>

            <div className='artverse_block_column'>
            <img className='card-img' loading="lazy" src={`${CDN}/projects/artverse/ShapesXR_card_logo.png`} alt=""/>
                <div className='artverse_block_items'>
                    <p style={{fontSize:'32px', fontWeight:'600'}}>ShapesXR</p>
                    <p>
                    VR Prototyping: Essential for designing and visualizing 3D VR environments.
                    </p>
                </div>
            </div>

            <div className='artverse_block_column'>
            <img className='card-img' loading="lazy" src={`${CDN}/projects/artverse/Unity_card_logo.png`} alt=""/>
                <div className='artverse_block_items'>
                    <p style={{fontSize:'32px', fontWeight:'600'}}>Unity</p>
                    <p>
                    Advanced VR Development: Explored the potential in developing full-scale VR applications.
                    </p>
                </div>
            </div>

            </div>


            <section>
                <div className='projectHeader'>
                    <div className='number'>07</div>
                    <h2 className='header'>My Learnings & Takeaways</h2>
                    <p style={{fontSize: '48px'}}>&#x1F4C8;</p>
                </div>

            </section>
           
            <div style={{width:'90%', maxWidth:"1024px", marginBottom:'5rem'}} className='artverse_bg fdcolumn'>

                <div className='fdrow'>
                    <div className='artverse_block_column'>
                        <img className='card-img-l' loading="lazy" src={`${CDN}/projects/artverse/art_l6.jpeg`} alt=""/>
                        <div className='artverse_block_items'>
                            <p style={{fontSize:'24px', fontWeight:'600'}}>Comprehensive Problem Solving</p>
                            <p>
                            I discovered that to address the challenge of art accessibility, we had to consider multiple aspects like engagement, technology integration, and education. Tackling these interconnected areas provided a more holistic solution.
    </p>
                        </div>
                    </div>

                    <div className='artverse_block_column'>
                    <img className='card-img-l' loading="lazy" src={`${CDN}/projects/artverse/art_l4.JPG`} alt=""/>
                        <div className='artverse_block_items'>
                            <p style={{fontSize:'24px', fontWeight:'600'}}>Insights into Art Education
    </p>
                            <p>
                            My museum visit for research gave me insight into traditional art education methods like info displays and telephone audio descriptions. Experiencing these firsthand sparked ideas for enhancing and digitizing these experiences.    </p>
                        </div>
                    </div>

                </div>

                <div className='fdrow'>

                    <div className='artverse_block_column'>
                    <img className='card-img-l' loading="lazy" src={`${CDN}/projects/artverse/art_l11.jpg`} alt=""/>
                        <div className='artverse_block_items'>
                            <p style={{fontSize:'24px', fontWeight:'600'}}>VR Prototyping
    </p>
                            <p>
                            Using tools like ShapesXR and Bezi Platform allowed me to prototype our Figma designs in a VR environment. This helped us visualize and test the user experience in a realistic setting, ensuring our concepts translated well into VR.

    </p>
                        </div>

                        

                    </div>

                    <div className='artverse_block_column'>
                    <img className='card-img-l' loading="lazy" src={`${CDN}/projects/artverse/art_l2.jpg`} alt=""/>
                        <div className='artverse_block_items'>
                            <p style={{fontSize:'24px', fontWeight:'600'}}>Exploring Unity

    </p>
                            <p>
                            While time and resource constraints prevented me from completing the project in Unity, the foundational skills I gained expanded my capabilities and opened up possibilities for future VR development.

    </p>
                        </div>
                    </div>

                    

                </div>

                <div className='fdrow'>

                <div className='artverse_block_column'>
                    <img className='card-img-l' loading="lazy" src={`${CDN}/projects/artverse/art_l3.JPG`} alt=""/>
                        <div className='artverse_block_items'>
                            <p style={{fontSize:'24px', fontWeight:'600'}}>Collaborative Creativity

    </p>
                            <p>
                            The diverse backgrounds and skills within our team were crucial in driving innovation. By combining different perspectives, we developed unique solutions that were both creative and practical.
    </p>
                        </div>
                    </div>

                <div className='artverse_block_column'>
                <img className='card-img-l ' loading="lazy" src={`${CDN}/projects/artverse/art_l5.jpg`} alt=""/>
                    <div className='artverse_block_items'>
                        <p style={{fontSize:'24px', fontWeight:'600'}}>Testing & Iteration
</p>
                        <p>
                        During this iterative process, we developed the 'My Gallery' feature, which turned out to be a unique selling point of our product. This highlights the value of continuous user testing to create features that resonate deeply.
</p>
                    </div>


                </div>


                </div>

                

                

                
            
            </div>


        </motion.div>
        </div>
        
        );
    
}
 
export default Artverse;