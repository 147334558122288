import React,{ useEffect } from "react";

import { NavLink, Link } from "react-router-dom";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import MotionPathPlugin from "gsap/MotionPathPlugin";
import CursorFollower from "../components/cursorFollower";
import Contact from "./contact";
import {motion} from 'framer-motion';
import ReactGA from 'react-ga';
// import ThreeCanvas from "../components/threeCanvas";
import './contact';


import { CDN } from '../config'; 
import { HashLink } from "react-router-hash-link";



function Home() {

    function refreshPage() {
        ScrollTrigger.refresh(true)
    }

    function handleClick() {
        const valueToAutoPopulate = "Hey \n \n Can you please mail me GNCTD case study? \n \n Thanks";
        document.getElementById("message").value = valueToAutoPopulate;
    }
    
    useEffect( () => {

        if(document.getElementById("go-down")){
            document.getElementById("go-down").addEventListener("click",() =>{
                window.scrollTo({
                    top: 500,
                    behavior: 'smooth'
                });   
        })}

        const talk = document.querySelector(".talk");

        if(talk){
        talk.addEventListener("click", function(){
            document.querySelector(".contact-pager").scrollIntoView();
        });}


        const GnctdContainer = document.querySelector("#gnctdContainer");

        if(GnctdContainer){
        GnctdContainer.addEventListener("click", function(){
            document.querySelector(".contact-pager").scrollIntoView();
        });}


        const tease = document.getElementById("resumeTeaseButton");

        tease.addEventListener("mousemove", function(e){
            const position = tease.getBoundingClientRect();

            const x = e.pageX - position.left - position.width /2;
            const y = e.pageY - position.top - position.height /2;

            tease.style.transform = "translate(" + x*1 + "px," + y*1 + "px)";
        })

        tease.addEventListener("mouseout", function(e){ 

            tease.style.transform = "translate(0,0)";
        }) 

        
    

        gsap.registerPlugin(ScrollTrigger);
        gsap.registerPlugin(MotionPathPlugin);
        // gsap.from(".text-trans", {duration:5, opacity:0, y:150, height:0})
        gsap.from(".main-header span", {duration:0.8, opacity:0, stagger:0.2, y:"80%",ease: "back.out(1.7)"})
        gsap.from(".para", {duration:0.5, delay:0.8, opacity:0, y:20})
        gsap.from(".job", { duration: 0.8, delay:1.3, opacity:0, y:40, ease: "bounce.out"})
        // gsap.from(".talk", { duration: 0.8, delay:1.3, opacity:0, y:80, ease: "bounce.out"})

        gsap.to( ".go-down",{ 
            scrollTrigger:{
                trigger:".p-2",
                toggleActions:"play none none reset"
        },duration:0.2, opacity:0})

        let works = document.querySelector('.works')
        let certificationsContainer = document.querySelector('.certifications-page')
        let interactionsContainer = document.querySelector('.Interactions_page')
        let courseProjectsContainer = document.querySelector('.Course_Projects_page')
        let stackContainer = document.querySelector('.stack-container')

        gsap.to( ".works h1",{ 
            scrollTrigger:{
                trigger:".works",
                start:"top top",
                end:"bottom top",
                scrub:1,
        },
        x:works.offsetWidth, 
        xPercent:-100})

        gsap.to( ".Interactions_page h1",{ 
            scrollTrigger:{
                trigger:".interactions_section-home",
                start:"top top",
                end:"bottom top",
                scrub:1,
        },
        x:interactionsContainer.offsetWidth, 
        xPercent:-100})

        gsap.to( ".Course_Projects_page h1",{ 
            scrollTrigger:{
                trigger:".Course_Projects-home",
                start:"top top",
                end:"bottom top",
                scrub:1,
        },
        x:courseProjectsContainer.offsetWidth, 
        xPercent:-100})

        gsap.to( ".certifications-page h1",{ 
            scrollTrigger:{
                trigger:".certifications",
                start:"top top",
                end:"bottom top",
                scrub:1,
        },
        x:certificationsContainer.offsetWidth, 
        xPercent:-100})

        gsap.to( ".stack-container h1",{ 
            scrollTrigger:{
                trigger:".stack-container",
                start:"top top",
                end:"bottom top",
                scrub:1,
        },
        x:stackContainer.offsetWidth, 
        xPercent:-100})

        gsap.to( ".p-1",{ 
            scrollTrigger:{
                trigger:".p-1",
                start:"top 80%",
                toggleActions:"play none none reset"
        },duration:0.2, opacity:1,y:0,scale:1 })

        gsap.to( ".p-2",{ 
            scrollTrigger:{
                trigger:".p-1",
                start:"20% 80%",
                toggleActions:"play none none reset"
        },duration:0.2, opacity:1,y:0,scale:1 })
    
        gsap.to( ".p-3",{ 
            scrollTrigger:{
                trigger:".p-2",
                start:"20% 70%",
                toggleActions:"play none none reset"
        },duration:0.2, opacity:1,y:0,scale:1 })

        if(window.screen.width > 1024){
            gsap.to( ".contact-pager",{ 
                scrollTrigger:{
                    trigger:".contact-pager",
                    start:"top 10%",
                    toggleActions:"play none none reset"
            },duration:0.5, opacity:1, pointerEvents:"all"})
        }else{
            gsap.to( ".contact-pager",{ 
                scrollTrigger:{
                    trigger:".contact-pager",
                    start:"top 50%",
                    toggleActions:"play none none reset"
            },duration:0.5, opacity:1, pointerEvents:"all"})
        }

        
        // ScrollTrigger.create({
        //     trigger:".p-3",
        //     start:"20% top",
        //     onEnter:() => gsap.to("body", {backgroundColor:"#0D0E13", overwrite:'auto'}),
        //     onLeaveBack:() => gsap.to("body", {backgroundColor:"#f8feff", overwrite:'auto'}),
        // })
        
        const project1 = document.querySelector(".p-1");
        project1.addEventListener("click", function(){
            ScrollTrigger.update(true);
            console.log("refreshed")
        })

    },[])

   
    return (
        <div><CursorFollower/>

        <motion.div
        animate={{opacity:1,  translateY:"0px"}}
        initial={{opacity:0,  translateY:"50px"}}
        transition={{ duration: 1.5 }}
        exit={{opacity:0,  translateY:"50px"}}>
                
        <div className="homeC">
            
            <div className="center">

                {/* <ThreeCanvas/> */}


                <h1 className="main-header">
                    <span className="hola_hover">Hola, </span>
                    <span>I'm </span>
                    <span>Sarath </span>
                    <span className="sarath_hover">Nekuri!</span>
                </h1>
            
                <p className="para">
                A passionate User Experience (UX) Designer with a focus on creating practical and engaging products. As a self-taught User Interface (UI) Developer, I love bringing designs to life. Currently, I'm graduating in Interactive Design at SCAD.
                <br></br>
                    <i style={{fontWeight:"200", marginTop:"1rem"}}>Open For Fall Internship 2024.</i>
                </p>
                {/* <p className="job" style={{fontSize:"1rem", textAlign:"center"}} >Currently Graduating in Interactive Design at SCAD</p> */}
                {/* <span className="talk" style={{paddingTop:"1rem", fontWeight:"bold"}} >Open for opportunities</span> */}
                <div className="job" style={{transform:"scale(1.25)"}}>
                    <div style={{marginTop:"1rem", textAlign:"center"}} id="resumeTeaseButton" className="contactButton teaseButton">
                        <a href = {`${CDN}/Sarath_Chand_Nekuri-Resume.pdf`} target = "_blank" rel="noreferrer" >View Resume</a>
                    </div>
                </div>
                
                    
            </div>

            <div id="go-down" className="go-down">
                <img src={`${CDN}/icons/down.svg`} alt="" /> 
            </div>

            <div className="Interactions_page">
                <div style={{width:"100%", height:'0', position:"sticky", top:"0"}}>
                    <h1 className="movable-header">
                        Interactions
                    </h1>
                </div>
                <div style={{display:"flex", alignItems:"center", width:"100%", justifyContent:"center"}}>
                    <div className="interactions_section-home">
                        <div className="home-is">
                                <div className="interactionIphone">
                                    <img style={{width:"90%", height:"auto"}} className="iphone" src={`${CDN}/interactions/iPhone15.png`} alt=""/>
                                    <p style={{position:"absolute"}}>loading...</p>
                                    <img style={{width:"67%"}} className="iphone-gif" loading="lazy" src={`${CDN}/interactions/gardentag1.gif`} alt=""/>
                                </div>

                        </div>
                        <div style={{display:"flex", flexDirection:"column", width:"400px"}}>
                            <div className="home-ism">
                                <img loading="lazy" className="bunker_interaction" src={`${CDN}/interactions/Secret_Bunker.gif`} alt=""/>
                            </div>
                            <div className="home-isb">
                                <NavLink className="submitButton" to="/interactions"><button>View More</button></NavLink>
                            </div>

                        </div>
                        <div className="home-is">
                                <div className="interactionIphone">
                                    <img style={{width:"90%", height:"auto"}} className="iphone" src={`${CDN}/interactions/iPhone15.png`} alt=""/>
                                    <p style={{position:"absolute"}}>loading...</p>
                                    <img style={{width:"67%"}} className="iphone-gif" loading="lazy" src={`${CDN}/interactions/Bike_Reserving.gif`} alt=""/>
                                </div>

                        </div>

                    </div>
                </div>
                
                
            </div>

            <div className="Course_Projects_page">
                <div style={{width:"100%", height:'0', position:"sticky", top:"0", zIndex:"-1"}}>
                    <h1 className="movable-header">
                        Selected Course Projects
                    </h1>
                </div>
                <div style={{display:"flex", alignItems:"center", width:"100%", justifyContent:"center"}}>
                <div className="Course_Projects-home">

                <div className="course-project-tile" style={{background:"#fff"}}>
                <div className="course-project-img"> <img loading="lazy" src={`${CDN}/projects/ArtVerse_web.png`} alt="" /> </div>
                    

                    <h2>ArtVerse – Bridging Gaps in Art Education</h2>
                    <ul>
                        <li>
                        ArtVerse is a VR museum that revolutionizes art education by making it accessible and interactive, breaking down barriers like cost and location to enhance global cultural appreciation.
                        </li>
                    </ul>
                        
                            <div className="project-ctas">
                                <div className="contactButton">
                                    <Link to="/artverse">
                                        Learn More
                                    </Link>
                                </div>
                                

                                <a target="_blank" rel="noreferrer" className="primaryButton"  href="https://www.figma.com/proto/yeib8RBe40pOeMSK1J0ya2/IXDS-724---Rainbow-%F0%9F%8C%88?page-id=179%3A4&node-id=347-30&viewport=-240%2C679%2C0.15&t=1PMqugUNFEVAkog5-9&scaling=contain&starting-point-node-id=347%3A30&hotspot-hints=0&show-proto-sidebar=1">
                                    View Design
                                </a>

                            </div>
                </div>

                <div className="course-project-tile" style={{background:"#fff"}}>
                <div className="course-project-img"> <img loading="lazy" src={`${CDN}/projects/GardenTag_web.png`} alt="" /> </div>
                    

                    <h2>GardenTag – Every Plant's Personal Caretaker</h2>
                    <ul>
                        <li>
                        GardenTag is a personalized plant care assistant that simplifies gardening by providing tailored health insights for each plant, ensuring optimal growth no matter your green thumb level.                        </li>

                    </ul>

                    <div className="project-ctas">
                        <div className="contactButton">
                            <Link to="/gardentag">
                                Learn More
                            </Link>
                        </div>
                        

                        <a target="_blank" rel="noreferrer" className="primaryButton"  href="https://www.figma.com/proto/0tQGP8UAe7LdpJGK61rbRt/IXDS-734?page-id=405%3A37&node-id=620-1527&viewport=70%2C-21%2C0.07&t=vIrPvNjKFlSPrS2g-9&scaling=scale-down&starting-point-node-id=620%3A1527&show-proto-sidebar=1">
                            View Design
                        </a>
                    </div>

                </div>

                </div>
                </div>
                
                
            </div>

            <div className="works" onClick={refreshPage}>

                {/* <div className="project p d-none">
                    <HashLink style={{cursor:"none"}} smooth to="/secret_bunker#bunker">
                        <img className="project-image first-p" src={`${CDN}/images/images/covac-thumbnail.png`} alt="" />
                    </HashLink>
                </div> */}

  
                <div style={{width:"100%", position:"sticky", top:"0"}}>
                <h1 className="movable-header">
                    Selected Case Studies
                </h1>
                </div>

                <div className="projects">
                    <a className="project-new-container p-0" style={{cursor:"none"}} target="_blank" rel="noreferrer" href="https://www.figma.com/proto/ztWtrsMWKu1gxf0xepVvgq/Portfolio?page-id=54%3A1219&type=design&node-id=812-3811&viewport=629%2C357%2C0.24&t=BsOzWf0SpNX7s2Xj-1&scaling=scale-down-width&mode=design" >
                        <h2>GNCTD Dashboard</h2>
                        <p>Project | Responsive Website | UX Designer from conception to delivery</p>
                        <img src={`${CDN}/images/images/GNCTD_iPhone_Mockup.png`} alt=""/>
                        <img src={`${CDN}/images/images/GNCTD_iMac_Mockup.png`} alt=""/>
                    </a>

                    <div style={{display:"flex", gap:"40px", maxWidth:"1024px"}}>              
                        <Link className="project-new-container p-1" style={{cursor:"none"}}  to="/rogalik-bakery">
                            <h2>Rogalik Bakery</h2>
                            <p>Concept Project | Responsive Website | UX Designer from conception to delivery</p>
                            <img src={`${CDN}/images/images/Rogalik+Mockup.png`} alt=""/>
                        </Link>

                        <Link className="project-new-container p-2" style={{cursor:"none"}}  onClick={refreshPage}  to="/covac">
                            <h2 style={{width:"100%", height:"81px"}}>CoVac</h2>
                            <p>Concept Project | Responsive Web App | UX Designer from conception to delivery</p>
                            <img src={`${CDN}/images/images/Covac+Mockup+IP14_3.png`} alt=""/>
                        </Link>

                        <Link className="project-new-container p-3" style={{cursor:"none"}}  onClick={refreshPage} to="/mia-restaurant">
                            <h2>Mia's Restaurant</h2>
                            <p>Concept Project | Mobile App | UX Designer from conception to delivery</p>
                            <img src={`${CDN}/images/images/Mias+Mockup.png`} alt=""/>
                        </Link>

                    </div>     
    
                   

                </div>

            </div> 

            <div className="certifications-page">
                <div style={{width:"100%", height:'0', position:"sticky", top:"0"}}>
                    <h1 className="movable-header">
                        Certifications
                    </h1>
                </div>
                <div style={{height:'auto'}} className="certifications-slider">

                    <ul className="certifications">
                        <li className="certification-frame">
                            <div className="certification-front">
                                <img src={`${CDN}/certifications/IxDF_1.png`} alt="" />
                                <div className="certification-text">
                                    <h2>How to Create a UX Portfolio</h2>
                                    <p>Interaction Design Foundation</p>
                                </div>
                            </div>
                            <div className="certification-back">
                                    <a className="deck-btn" target = "_blank" rel="noreferrer" href="https://www.interaction-design.org/members/nekuri-sarath-chand/certificate/course/c6b69d44-e075-4b62-bc62-4bb491a0af72">
                                        View certificate
                                    </a>
                            </div>
                        </li>

                        <li className="certification-frame">
                            <div className="certification-front">
                                <img src={`${CDN}/certifications/IxDF_5.jpeg`} alt="" />
                                <div className="certification-text">
                                    <h2>AI for Designers</h2>
                                    <p>Interaction Design Foundation</p>
                                </div>
                            </div>
                            <div className="certification-back">
                                    <a className="deck-btn" target = "_blank" rel="noreferrer" href="https://www.interaction-design.org/members/nekuri-sarath-chand/certificate/course/9741cc27-dc0e-4d9d-bb07-b48ae5f5517e">
                                        View certificate
                                    </a>
                            </div>
                        </li>

                        <li className="certification-frame">
                            <div className="certification-front">
                                <img src={`${CDN}/certifications/IxDF_2.png`} alt="" />
                                <div className="certification-text">
                                    <h2>Design for the 21st Century with Don Norman</h2>
                                    <p>Interaction Design Foundation</p>
                                </div>
                            </div>
                            <div className="certification-back">
                                <a className="deck-btn" target = "_blank" rel="noreferrer" href="https://www.interaction-design.org/members/nekuri-sarath-chand/certificate/course/7208cfa1-7e3b-48f2-90f7-0faa0387333c">
                                    View certificate
                                </a>
                            </div>
                        </li>

                        <li className="certification-frame">
                            <div className="certification-front">
                                <img src={`${CDN}/certifications/google.png`} alt="" />
                                <div className="certification-text">
                                    <h2>Google UX Design</h2>
                                    <p>Coursera</p>
                                </div>
                            </div>
                            <div className="certification-back">
                                <a className="deck-btn" href="https://coursera.org/share/6e576e42f81c5ee97e75375b6cd7d1bc">
                                    View certificate
                                </a>
                            </div>
                        </li>

                        <li className="certification-frame">
                            <div className="certification-front">
                                <img src={`${CDN}/certifications/IxDF_3.png`} alt="" />
                                <div className="certification-text">
                                    <h2>Psychology of E-Commerce: How to Sell Online</h2>
                                    <p>Interaction Design Foundation</p>
                                </div>
                            </div>
                            <div className="certification-back">
                                <a className="deck-btn" target = "_blank" rel="noreferrer" href="https://www.interaction-design.org/members/nekuri-sarath-chand/certificate/course/be3a904f-c250-428e-8276-a43ff4bd8b79">
                                    View certificate
                                </a>
                            </div>
                        </li>

                        <li className="certification-frame">
                            <div className="certification-front">
                                <img src={`${CDN}/certifications/IxDF_4.png`} alt="" />
                                <div className="certification-text">
                                    <h2>Mobile User Experience (UX) Design</h2>
                                    <p>Interaction Design Foundation</p>
                                </div>
                            </div>
                            <div className="certification-back">
                                <a className="deck-btn" target = "_blank" rel="noreferrer" href="https://www.interaction-design.org/members/nekuri-sarath-chand/certificate/course/07956bbb-ac19-4c85-8485-5166e2da3ab1">
                                View certificate
                                </a>
                            </div>
                        </li>



                        <li className="certification-frame">
                            <div className="certification-front">
                                <img src={`${CDN}/certifications/IxDF_1.png`} alt="" />
                                <div className="certification-text">
                                    <h2>How to Create a UX Portfolio</h2>
                                    <p>Interaction Design Foundation</p>
                                </div>
                            </div>
                            <div className="certification-back">
                                    <a className="deck-btn" target = "_blank" rel="noreferrer" href="https://www.interaction-design.org/members/nekuri-sarath-chand/certificate/course/c6b69d44-e075-4b62-bc62-4bb491a0af72">
                                        View certificate
                                    </a>
                            </div>
                        </li>

                        <li className="certification-frame">
                            <div className="certification-front">
                                <img src={`${CDN}/certifications/IxDF_5.jpeg`} alt="" />
                                <div className="certification-text">
                                    <h2>AI for Designers</h2>
                                    <p>Interaction Design Foundation</p>
                                </div>
                            </div>
                            <div className="certification-back">
                                    <a className="deck-btn" target = "_blank" rel="noreferrer" href="https://www.interaction-design.org/members/nekuri-sarath-chand/certificate/course/9741cc27-dc0e-4d9d-bb07-b48ae5f5517e">
                                        View certificate
                                    </a>
                            </div>
                        </li>

                        <li className="certification-frame">
                            <div className="certification-front">
                                <img src={`${CDN}/certifications/IxDF_2.png`} alt="" />
                                <div className="certification-text">
                                    <h2>Design for the 21st Century with Don Norman</h2>
                                    <p>Interaction Design Foundation</p>
                                </div>
                            </div>
                            <div className="certification-back">
                                <a className="deck-btn" target = "_blank" rel="noreferrer" href="https://www.interaction-design.org/members/nekuri-sarath-chand/certificate/course/7208cfa1-7e3b-48f2-90f7-0faa0387333c">
                                    View certificate
                                </a>
                            </div>
                        </li>

                        <li className="certification-frame">
                            <div className="certification-front">
                                <img src={`${CDN}/certifications/google.png`} alt="" />
                                <div className="certification-text">
                                    <h2>Google UX Design</h2>
                                    <p>Coursera</p>
                                </div>
                            </div>
                            <div className="certification-back">
                                <a className="deck-btn" href="https://coursera.org/share/6e576e42f81c5ee97e75375b6cd7d1bc">
                                    View certificate
                                </a>
                            </div>
                        </li>

                        <li className="certification-frame">
                            <div className="certification-front">
                                <img src={`${CDN}/certifications/IxDF_3.png`} alt="" />
                                <div className="certification-text">
                                    <h2>Psychology of E-Commerce: How to Sell Online</h2>
                                    <p>Interaction Design Foundation</p>
                                </div>
                            </div>
                            <div className="certification-back">
                                <a className="deck-btn" target = "_blank" rel="noreferrer" href="https://www.interaction-design.org/members/nekuri-sarath-chand/certificate/course/be3a904f-c250-428e-8276-a43ff4bd8b79">
                                    View certificate
                                </a>
                            </div>
                        </li>

                        <li className="certification-frame">
                            <div className="certification-front">
                                <img src={`${CDN}/certifications/IxDF_4.png`} alt="" />
                                <div className="certification-text">
                                    <h2>Mobile User Experience (UX) Design</h2>
                                    <p>Interaction Design Foundation</p>
                                </div>
                            </div>
                            <div className="certification-back">
                                <a className="deck-btn" target = "_blank" rel="noreferrer" href="https://www.interaction-design.org/members/nekuri-sarath-chand/certificate/course/07956bbb-ac19-4c85-8485-5166e2da3ab1">
                                View certificate
                                </a>
                            </div>
                        </li>
                    </ul>

                    

                </div>
            </div>


            <div className="stack-container">
                <div style={{width:"100%", height:'0', position:"sticky", top:"0"}}>
                <h1 className="movable-header">
                    Skills
                </h1>
                </div>
                {/* <h3 className="stackH3">Designed & developed by me with <span style={{color:"red"}}>&#10084;</span> and</h3> */}
                
                <div style={{height:'auto', overflow:'hidden'}}>
                    <div className="stack-stuff">
                        <div className="stack-icons">
                            <img className="reactpng" src={`${CDN}/icons/Figma_icon.svg`} alt="" />
                            <img className="jspng" src={`${CDN}/icons/Xd.svg`} alt="" />
                            <img className="gsappng" src={`${CDN}/icons/Ps.svg`} alt="" />
                            <img className="blenderpng" src={`${CDN}/icons/blender.png`} alt="" />
                            <img className="pspng" src={`${CDN}/icons/Pr.svg`} alt="" />
                            <img className="aipng" src={`${CDN}/icons/Ai.svg`} alt="" />
                            <img className="hours" src={`${CDN}/icons/hs.png`} alt="" />
                            <img className="thinking" src={`${CDN}/icons/react.png`} alt="" />
                            <img className="insp" src={`${CDN}/icons/Webflow.svg`} alt="" />
                            <img className="lines" src={`${CDN}/icons/Dw.svg`} alt="" />
                            <img className="files" src={`${CDN}/icons/gsap.png`} alt="" />
                        </div>
                        
                    </div>
                    <div className="breathe">
                        <img className="oBgo" src={`${CDN}/icons/outeryellow.svg`} alt="" />
                        <img className="mBgo" src={`${CDN}/icons/outeryellow.svg`} alt="" />
                        <img className="iBgo" src={`${CDN}/icons/outeryellow.svg`} alt="" />
                        <img className="oBg" src={`${CDN}/icons/outerbg.svg`} alt="" />
                        <img className="mBg" src={`${CDN}/icons/outerbg.svg`} alt="" />
                        <img className="iBg" src={`${CDN}/icons/outerbg.svg`} alt="" />
                    </div>
                </div>

                
                
            </div>            

            <div className="footer" id="socialNetworks">
                
                <a href="https://www.linkedin.com/in/sarathchand/" target = "_blank" rel="noreferrer"  className="s-i "> <p>LinkedIn</p> <span className="borderl"></span></a >
                <a href="mailto:sarathchand19@gmail.com" target = "_blank" rel="noreferrer"  className="s-i "> <p> Mail</p> <span className="borderm"></span></a >       
                <a href="https://www.behance.net/sarathchand" target = "_blank" rel="noreferrer"  className="s-i "> <p>Behance</p> <span className="borderb"></span></a >
                <a href="https://github.com/sarathchandnekuri" target = "_blank" rel="noreferrer"  className="s-i "> <p>Github</p> <span className="borderb"></span></a >
                {/* <a href="https://twitter.com/nsarathsunny" target = "_blank" rel="noreferrer"  className="s-i "> <p>Twitter</p> <span className="bordert"></span></a > */}
                {/* <a href="https://www.instagram.com/sarath_chand_19/" target = "_blank" rel="noreferrer"  className="s-i "> <p>Instagram</p> <span className="borderi"></span></a > */}
                <div className="social-logos">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>

    <div className="contact-pager" id="contactPager">
        <Contact/>
    </div>

            
            
               
        </div>
        </motion.div> 
        </div>
        
        
     );
}

export default Home;