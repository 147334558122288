import React from "react";

function Resume() {
    return ( 
        <div>
            <cursorFollower/>
        </div>
     );
}

export default Resume;