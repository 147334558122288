import React from 'react';
import ReactDOM from 'react-dom';
import './styles.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ScrollToTop from 'react-router-scroll-top'
import {HashRouter} from 'react-router-dom';

ReactDOM.render(
  <HashRouter>
      <ScrollToTop>
        <App/>
      </ScrollToTop>
  </HashRouter>
  ,
  document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
